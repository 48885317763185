import {AxiosInstance} from 'axios'; // Importa axios
import {actions} from '@/utils/store'; // Importa tu store y acciones

export default class Auth {
  static async login(axios: AxiosInstance, username: string, password: string) {
    const response = await axios.post(
        '/graphql/',
        `mutation{
          tokenAuth(
            username:"${username}"
            password:"${password}"
          ){
            token
            refreshToken
            success
            errors
            user{
              id
              pk
              username
              email
            }
          }
        }`,
        {
          headers: {
            'Content-Type': 'application/graphql',
          }
        }
    ).catch(function (error) {
      if (error.response) {
        actions.actionsSetErrorMessage('Usuario o contraseña incorrectos');
        return null
      } else if (error.request) {
        actions.actionsSetErrorMessage('Usuario o contraseña incorrectos');
        return null
      } else {
        actions.actionsSetErrorMessage('Usuario o contraseña incorrectos');
        return null
      }
    });

    if (response !== null && response.data !== undefined ) {
      if (response.data.data.tokenAuth.success){
        return response.data.data.tokenAuth;
      } else {
        actions.actionsSetErrorMessage(response.data.data.tokenAuth.errors.nonFieldErrors[0].message);
        return null
      }
    } else {
      return null;
    }
  }

  static async logout(axios: AxiosInstance, refreshToken: string) {
    const response = await axios.post(
        '/graphql/',
        `mutation{
          revokeToken(
              refreshToken:"${refreshToken}"
          ){
            revoked
            success
            errors
          }
        }`,
        {
          headers: {
            'Content-Type': 'application/graphql',
          }
        }
    ).catch(function (error) {
      if (error.response) {
          actions.actionsSetErrorMessage('Usuario o contraseña incorrectos');
          return null
      } else if (error.request) {
          actions.actionsSetErrorMessage('Usuario o contraseña incorrectos');
          return null
      } else {
          actions.actionsSetErrorMessage('Usuario o contraseña incorrectos');
          return null
      }
    });

      if (response !== null && response.data !== undefined) {
          if (response.data.data.revokeToken.success) {
              return response.data.data.revokeToken.success;
          } else {
              actions.actionsSetErrorMessage(response.data.data.revokeToken.errors.nonFieldErrors[0].message);
              return null
          }
      } else {
      return null;
    }
  }

  static async me(axios: AxiosInstance) {
    const response = await axios.post(
        '/graphql/',
        `query{
          me {
            id
            username
            email
            firstName
            lastName
            phone
            profile {
              id
              winner
              gamer
            }
          }
        }`,
        {
          headers: {
            'Content-Type': 'application/graphql'
          }
        }
    ).catch(function (error) {
      if (error.response) {
        console.log("se partio 1 ")

        return error.response
      } else if (error.request) {
        console.log("se partio 2 ")

        return error.request
      } else {
        console.log("se partio 3 ", error)
        return null
      }
    });
    if (response && response.data) {
      return response.data.data.me;
    } else {
      console.log("es null: ", response)
      return null;
    }
  }
}