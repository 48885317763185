<template>
  <div class="file-upload text-center position-relative">
    <div v-for="(image, index) in imageUrls" :key="index" class="image-preview-container">
      <img :src="image.url" alt="Ficheros" class="preview-image"/>
      <input 
        v-model="image.text" 
        class="image-text-input" 
        placeholder="Descripción de la imagen"
      />
      <button @click="removeImage(index)" class="btn-remove">Eliminar</button>
    </div>
    <span class="d-block text-muted">
      Eliminar Ficheros
      <span @click="uploadImages" class="text-black fw-medium position-relative">
        Subir
      </span>
    </span>
    <input
        type="file"
        class="d-block shadow-none border-0 position-absolute start-0 end-0 top-0 bottom-0 z-1 opacity-0"
        ref="fileInput"
        @change="handleFileChange"
        multiple
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import * as StateStore from "@/utils/store";

export default defineComponent({
  name: "ImageUpload",
  props: {
    axios: {
      type: [Object, Function],
      required: true,
    },
  },
  data() {
    return {
      selectedFiles: [] as File[],
      imageUrls: [] as { url: string; text: string }[], // Guardar URL y texto
    };
  },
  methods: {
    handleFileChange(event: Event) {
      const input = event.target as HTMLInputElement;
      if (input.files) {
        const files = Array.from(input.files);
        this.selectedFiles = files.filter(file => file.type.startsWith('image/'));
        this.previewImages();
        this.$emit('files-selected', this.selectedFiles); // Emite los archivos seleccionados
      }
    },
    previewImages() {
      this.imageUrls = [];
      this.selectedFiles.forEach(file => {
        const reader = new FileReader();
        reader.onload = (event) => {
          if (event.target?.result) {
            this.imageUrls.push({ url: event.target.result as string, text: "" });
          }
        };
        reader.readAsDataURL(file);
      });
    },
    async uploadImages() {
      if (this.selectedFiles.length === 0) return;

      const formData = new FormData();
      this.selectedFiles.forEach((file, index) => {
        formData.append("images[]", file); // Manejar múltiples archivos
        formData.append(`texts[${index}]`, this.imageUrls[index]?.text || ""); // Texto asociado a cada imagen
      });

      try {
        const response =  await StateStore.actions.actionsDeleteArticles({
            axios: this.axios,
            formData,
          });

        if (response !== null) {
         console.log("Image upload successful.", response);
         
          // Maneja la respuesta del servidor si es necesario
        } else {
          console.error("Image upload failed.");
        }
      } catch (error) {
        console.error("Error uploading images:", error);
      }
    },
    removeImage(index: number) {
      this.imageUrls.splice(index, 1); // Eliminar imagen de la vista previa
      this.selectedFiles.splice(index, 1); // Eliminar archivo de la lista de archivos seleccionados
    }
  },
});
</script>

<style scoped>
.preview-image {
  max-width: 100%;
  max-height: 300px;
  margin-bottom: 10px;
}

.image-text-input {
  width: 100%;
  margin-bottom: 10px;
}

.btn-remove {
  background-color: red;
  color: white;
  border: none;
  padding: 5px;
  cursor: pointer;
}

.image-preview-container {
  position: relative;
  margin-bottom: 20px;
}
</style>
