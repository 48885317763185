import { AxiosInstance } from 'axios'; // Importa axios
import * as StateStore from "@/utils/store";

export default class Files {
  /**
   * Crea múltiples archivos utilizando la mutación CreateMultipleFiles
   * @param axios - Instancia de Axios
   * @param filesData - Lista de datos de archivos a crear
   */
  static async createMultiple(axios: AxiosInstance, filesData: Array<{ name: string; text: string }>) {
    try {
      const response = await axios.post(
        '/graphql/',
        {
          query: `
            mutation CreateMultipleFiles($filesData: [JSONString!]!) {
              createMultipleFiles(filesData: $filesData) {
                insertedIds
              }
            }
          `,
          variables: {
            filesData: filesData.map(file => ({
              name: file.name,
              text: file.text,
            })),
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      // Manejo de la respuesta
      if (response.data?.data?.createMultipleFiles?.insertedIds) {
        console.log('IDs insertados:', response.data.data.createMultipleFiles.insertedIds);
        return response.data.data.createMultipleFiles.insertedIds;
      } else {
        console.error('Error al crear archivos:', response.data.errors);
        StateStore.actions.actionsSetErrorMessage(response.data.errors);
        return null;
      }
    } catch (error) {
      console.error('Error en la mutación:', error);
      StateStore.actions.actionsSetErrorMessage(['Error en la creación de archivos.']);
      return null;
    }
  }

  /**
   * Lista todos los archivos
   * @param axios - Instancia de Axios
   */
  static async list(axios: AxiosInstance) {
    try {
      const response = await axios.post(
        '/graphql/',
        `query {
          filesList {
            id
            name
            text
          }
        }`,
        {
          headers: {
            'Content-Type': 'application/graphql',
          },
        }
      );

      console.log('Files list:', response.data.data?.filesList);
      return response.data.data?.filesList || [];
    } catch (error) {
      console.error('Error al listar archivos:', error);
      StateStore.actions.actionsSetErrorMessage(['Error al listar los archivos.']);
      return [];
    }
  }

  /**
   * Elimina un archivo por ID
   * @param axios - Instancia de Axios
   * @param id - ID del archivo a eliminar
   */
  static async delete(axios: AxiosInstance, id: number) {
    try {
      const response = await axios.post(
        '/graphql/',
        {
          query: `
            mutation deleteFile($id: ID!) {
              deleteFile(id: $id) {
                ok
              }
            }
          `,
          variables: {
            id: id,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data?.data?.deleteFile?.ok) {
        console.log('Archivo eliminado correctamente.');
        return true;
      } else {
        console.error('Error al eliminar archivo:', response.data.errors);
        StateStore.actions.actionsSetErrorMessage(response.data.errors);
        return false;
      }
    } catch (error) {
      console.error('Error en la mutación de eliminación:', error);
      StateStore.actions.actionsSetErrorMessage(['Error al eliminar el archivo.']);
      return false;
    }
  }
}
