<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
        class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <!--      <div class="d-sm-flex align-items-center">-->
      <!--        <a-->
      <!--          class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0 text-decoration-none"-->
      <!--          href="#"-->
      <!--          data-bs-toggle="modal"-->
      <!--          data-bs-target="#createNewUserModal"-->
      <!--          @click.prevent="handleModalClosed"-->
      <!--        >-->
      <!--          Agregar Jugador-->
      <!--          <i class="flaticon-plus position-relative ms-5 fs-12"></i>-->
      <!--        </a>-->
      <!--      </div>-->
      <div class="d-flex align-items-center">
        <form class="search-box position-relative me-15">
          <input
              type="text"
              class="form-control shadow-none text-black rounded-0 border-0"
              placeholder="Buscar Jugador"
          />
          <button
              type="submit"
              class="bg-transparent text-primary transition p-0 border-0"
          >
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
          <tr>
            <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
            >
              Email
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
            >
              Nombre
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
            >
              Apellidos
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
            >
              Juego
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Estado del Kit de Premio
            </th>
            <!-- <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
            >
              Acciones
            </th> -->
          </tr>
          </thead>
          <tbody>
          <tr v-for="(user, index) in items" :key="index">
            <td class="shadow-none lh-1 fw-medium text-primary long-text">
              {{ user.email }}
            </td>
            <td class="shadow-none lh-1 fw-medium text-black-emphasis long-text">
              {{ user.firstName }}
            </td>
            <td class="shadow-none lh-1 fw-medium text-muted long-text">
              {{ user.lastName }}
            </td>
            <th class="shadow-none lh-1 fw-medium text-black-emphasis title ps-0">
              <div class="d-flex align-items-center">
                <div class="d-flex align-items-center ms-5 fs-md-15 fs-lg-16 long-text">
                  <span class="badge bg-success rounded-circle p-2">
                    {{ user.profile?.progress[0]?.game?.name }}
                  </span>
                </div>
              </div>
            </th>
            <th
                class="shadow-none lh-1 fw-medium text-black-emphasis title ps-0"
            >
              <div class="d-flex align-items-center">
                <div class="d-flex align-items-center ms-5 fs-md-15 fs-lg-16 long-text">
                  {{ user.premio[0]?.status }}
                </div>
              </div>
            </th>
            <!-- <td class="action-link">
              <router-link :to="`/profile/${user.id}`">
                <i class="ph ph-eye me-2 action-link" style="font-size: 24px;"/>
              </router-link>
              <i
                  class="ph ph-pencil action-link"
                  style="font-size: 24px;"
                  data-bs-toggle="modal"
                  data-bs-target="#createNewUserModal"
                  @click="openModal(user)"
              />
              <i
                  class="ph ph-trash ms-2 action-link"
                  style="font-size: 24px;"
                  @click="deleteUser(user.id)"
              />
            </td> -->
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="pagination-area d-md-flex mb-25 justify-content-between align-items-center">
      <p class="ms-20 mb-0 text-paragraph">
        Mostrando <span class="fw-bold">{{ paginatedItems.length }}</span> de
        <span class="fw-bold">{{ items.length }}</span> Resultados
      </p>
      <nav class="mt-15 mt-md-0">
        <ul class="pagination mb-0">
          <li class="page-item">
            <a class="page-link" @click.prevent="goToPreviousPage" aria-label="Previous">
              <i class="flaticon-chevron-1"></i>
            </a>
          </li>
          <li class="page-item" v-for="page in totalPages" :key="page">
            <a class="page-link" @click.prevent="goToPage(page)">{{ page }}</a>
          </li>
          <li class="page-item">
            <a class="page-link" @click.prevent="goToNextPage" aria-label="Next">
              <i class="flaticon-chevron"></i>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
  <CreateNewUserModal
      :axios="axios"
      :id="selectedItem?.id"
      :no-close-on-backdrop="true"
      :title="title"
      @modal-closed="handleModalClosed"
  />
</template>

<script>
import {computed} from "vue";
import CreateNewUserModal from "./CreateNewUserModal.vue";
import * as StateStore from "@/utils/store"


export default {
  name: "UsersWinnerList",
  props: {
    axios: {
      type: [Object, Function],
      required: true
    }
  },
  components: {
    CreateNewUserModal,
  },
  data() {
    return {
      searchQuery: '',
      selectedItem: null,
      itemsPerPage: 10,
      currentPage: 1,
      items: [],
      title: computed(() => this.selectedItem === null ? 'Crear Nueva ' : 'Editar '),

    }
  },
  computed: {
    filteredAnswers() {
      return this.items.filter(item => {
        return (
            item.username.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            item.email.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            item.firstName.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            item.lastName.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      });
    },
    paginatedItems() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredAnswers.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredAnswers.length / this.itemsPerPage);
    }
  },
  methods: {
    filterResults() {
      // Este método se llama al enviar el formulario, pero no es necesario hacer nada aquí
    },
    handleModalClosed() {
      this.selectedItem = null;
      // console.log("selectedItem.value: ", selectedItem.value);

    },
    openModal(item) {
      this.selectedItem = item;
      console.log("selectedItem: ", this.selectedItem);

    },
    async deleteUser(id) {
      if (confirm("¿Está seguro de que desea eliminar el Jugador?")) {
        console.log('El usuario confirmó la eliminación');
        await StateStore.actions.actionsDeleteUser({'axios': this.axios, 'id': id});
        location.reload();
      } else {
        console.log('El usuario canceló la eliminación');
      }
    },
    goToPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    goToPage(page) {
      this.currentPage = page;
    },
    goToNextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    async getAllUserWinner() {
      await StateStore.actions.actionsGetAllUsersWinnerList({'axios': this.axios});
      this.items = StateStore.getters.getUsersWinnerList();
    }
  },
  created() {
    this.getAllUserWinner();
  },
}
// export default defineComponent({
//   name: "UsersList",
//   props: {
//     axios: {
//       type: [Object, Function],
//       required: true
//     }
//   },
//   components: {
//     CreateNewUserModal,
//   },
//   setup(props) {
//     const selectedItem = ref(null);
//     const title = computed(() => selectedItem.value === null ? 'Crear Nuevo ' : 'Editar ');
//     const users = ref([] as any[]);
//
//     return {
//       loading: ref(false),
//       alert: ref(false),
//       users,
//       selectedItem,
//       title,
//       handleModalClosed() {
//         selectedItem.value = null;
//         // console.log("selectedItem.value: ", selectedItem.value);
//
//       },
//       openModal(item) {
//         selectedItem.value = item;
//         console.log("selectedItem.value: ", selectedItem.value);
//
//       },
//       async deleteUser(id: number) {
//         if (confirm("¿Está seguro de que desea eliminar el usuario?")) {
//           console.log('El usuario confirmó la eliminación');
//           await StateStore.actions.actionsDeleteUser({'axios': props.axios, 'id': id});
//           location.reload();
//         } else {
//           console.log('El usuario canceló la eliminación');
//         }
//       },
//       async getAllUsers() {
//         await StateStore.actions.actionsGetAllUsersList({'axios': props.axios});
//         users.value = StateStore.getters.getUsersList();
//       }
//     };
//   },
//   created() {
//     this.getAllUsers();
//   },
// });

</script>
<style>
.long-text {
  white-space: pre-wrap; /* Permite que el texto se divida en varias líneas */
  word-break: break-word; /* Rompe las palabras largas */
}
</style>