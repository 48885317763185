<template>
  <div
    ref="mymodal"
    class="modal fade createNewModal"
    id="createNewQuestionModal"
    tabindex="-1"
    aria-hidden="true"
    @click.self="handleBackdropClick"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-15 p-md-40">
        <div class="modal-header ps-0 pe-0 pt-0 pb-15 pb-md-25">
          <h5 class="modal-title fw-bold text-black">{{ title }} Pregunta</h5>
        </div>
        <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25">
          <form @submit.prevent="submitForm">
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <div :class="['form-group mb-15 mb-md-25', { 'has-error': !item.name && showError }]">
                  <label class="text-black fw-semibold mb-10">Nombre</label>
                  <input
                    v-model="item.name"
                    type="text"
                    class="form-control shadow-none rounded-0 text-black"
                    placeholder="e.g. Johny"
                  />
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div :class="['form-group mb-15 mb-md-25', { 'has-error': !item.point && showError }]">
                  <label class="text-black fw-semibold mb-10">Puntos</label>
                  <input
                    v-model="item.point"
                    type="number"
                    class="form-control shadow-none rounded-0 text-black"
                    placeholder="e.g. 10"
                    required
                  />
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div :class="['form-group mb-15 mb-md-25', { 'has-error': !item.level && showError }]">
                  <label class="text-black fw-semibold mb-10">Nivel</label>
                  <select
                    v-model="item.level"
                    class="form-control shadow-none rounded-0 text-black"
                    required
                  >
                    <option
                      v-for="(levelOption, index) in levels"
                      :value="levelOption.id"
                      :key="index"
                    >
                      {{ levelOption.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div v-if="error || showError">
              <div class="row">
                <span class="text-muted text-danger">{{ error }}</span>
              </div>
            </div>
            <div class="d-flex justify-content-between">
              <button
                class="btn-cancel default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 rounded-1"
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Cancelar
              </button>
              <button
                class="btn-accept default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 rounded-1"
                type="submit"
                :disabled="showError"
              >
                {{ title }} Pregunta
              </button>
            </div>
          </form>
        </div>
        <button
          type="button"
          class="btn-close shadow-none"
          data-bs-dismiss="modal"
          aria-label="Close"
        ></button>
      </div>
    </div>
  </div>
</template>

<script>
import * as StateStore from "@/utils/store";

export default {
  name: "CreateNewQuestionsModal",
  props: {
    axios: {
      type: [Object, Function],
      required: true
    },
    id: Number,
    noCloseOnBackdrop: Boolean,
    edit: Boolean,
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      error: '',
      showError: false,
      item: {
        name: '',
        level: null,
        point: null
      },
      levels: []
    };
  },
  watch: {
    async id(newVal) {
      if (newVal > 0) {
        await StateStore.actions.actionsGetQuestions({ axios: this.axios, id: newVal });
        this.item = await StateStore.getters.getQuestionsRetrieve();
      } else {
        this.item = { name: '', level: null, point: null };
      }
    }
  },
  mounted() {
    this.$refs.mymodal.addEventListener('hidden.bs.modal', this.handleModalHidden);
  },
  beforeUnmount() {
    this.$refs.mymodal.removeEventListener('hidden.bs.modal', this.handleModalHidden);
  },
  methods: {
    handleModalHidden() {
      this.$emit('modal-closed');
    },
    handleBackdropClick(event) {
      if (this.noCloseOnBackdrop) {
        event.stopPropagation();
      }
    },
    async getAllLevels() {
      await StateStore.actions.actionsGetAllLevelList({ axios: this.axios });
      this.levels = StateStore.getters.getLevelList();
    },
    async submitForm() {
      this.showError = !this.item.name || !this.item.point || !this.item.level;
      if (this.showError) {
        this.error = 'Todos los campos son necesarios';
        return;
      }

      this.error = '';
      try {
        if (!this.id) {
          // Creación
          await StateStore.actions.actionsCreateQuestions({
            axios: this.axios,
            name: this.item.name,
            level: this.item.level,
            point: this.item.point,
          });
        } else {
          // Edición
          await StateStore.actions.actionsEditQuestions({
            axios: this.axios,
            id: this.id,
            name: this.item.name,
            level: this.item.level,
            point: this.item.point,
          });
        }
        location.reload();
      } catch (error) {
        this.error = 'Error al guardar la Pregunta';
        console.error(this.error, error);
      }
    }
  },
  created() {
    this.getAllLevels();
  }
};
</script>

<style scoped>
.form-group {
  position: relative;
}

.has-error input,
.has-error select {
  border-color: red;
}
</style>
