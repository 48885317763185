<template>
  <div
    :class="[
      'sidebar-area position-fixed start-0 top-0 bg-black h-100vh transition',
      { active: stateStoreInstance.open },
    ]"
    id="sidebar-area"
  >
    <div class="logo position-absolute start-0 end-0 top-0 bg-black">
      <router-link
        to="/"
        class="d-flex  align-items-center text-white text-decoration-none"
      >
        <img  src="../../assets/images/logo1.png" alt="logo-icon" style="height: 90px; width: 150px"/>
        <!--        <span class="fw-bold ms-10">CESPT</span>-->
      </router-link>
     
      <button
        class="sidebar-burger-menu position-absolute lh-1 bg-transparent p-0 border-0"
        @click="stateStoreInstance.onChange"
      >
        <i class="ph-duotone ph-caret-double-right"></i>
      </button>
    </div>
    <div class="sidebar-menu">
      <ul
        class="sidebar-navbar-nav ps-0 mb-0 list-unstyled accordion"
        id="sidebarNavAccordion"
      >
        <li class="sidebar-nav-item bg-transparent" >
          <router-link to="/" class="sidebar-nav-link d-block">
            <i class="flaticon-more-1"></i>
            <span class="title">Dashboard</span>
          </router-link>
        </li>
        <!--        <li class="sidebar-nav-item">-->
        <!--          <router-link to="/file-manager" class="sidebar-nav-link d-block">-->
        <!--            <i class="flaticon-folder"></i>-->
        <!--            <span class="title">File Manager</span>-->
        <!--          </router-link>-->
        <!--        </li>-->
        <!--        <li class="sidebar-nav-item">-->
        <!--          <router-link to="/changelog" class="sidebar-nav-link d-block">-->
        <!--            <i class="flaticon-award-1"></i>-->
        <!--            <span class="title">Changelog</span>-->
        <!--          </router-link>-->
        <!--        </li>-->
        <!--        <li class="sub-title sidebar-nav-item">-->
        <!--          <span class="d-block text-uppercase fw-medium">Menú</span>-->
        <!--        </li>-->
        <!--        <li class="sidebar-nav-item">-->
        <!--          <router-link to="/calendar" class="sidebar-nav-link d-block">-->
        <!--            <i class="flaticon-date-1"></i>-->
        <!--            <span class="title">Calendar</span>-->
        <!--          </router-link>-->
        <!--        </li>-->
        <!--        <li class="sidebar-nav-item">-->
        <!--          <router-link to="/chat" class="sidebar-nav-link d-block">-->
        <!--            <i class="flaticon-chat-1"></i>-->
        <!--            <span class="title">Chat</span>-->
        <!--          </router-link>-->
        <!--        </li>-->
        <li
          class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0"
        >
          <a
            href="#"
            class="accordion-button collapsed rounded-0 shadow-none bg-transparent d-block"
            data-bs-toggle="collapse"
            data-bs-target="#sidebarCollapseTwo"
            aria-expanded="false"
            aria-controls="sidebarCollapseTwo"
          >
            <i class="flaticon-rocket"></i>
            <span class="title">Administración</span>
          </a>
          <div
            id="sidebarCollapseTwo"
            class="accordion-collapse collapse"
            data-bs-parent="#sidebarNavAccordion"
          >
            <div class="accordion-body">
              <ul class="sidebar-sub-menu ps-0 mb-0 list-unstyled bg-transparent">
                <li class="sidebar-sub-menu-item bg-transparent">
                  <router-link to="/users-list" class="sidebar-sub-menu-link bg-transparent">
                    Usuarios
                  </router-link>
                </li>
                <!--                <li class="sidebar-sub-menu-item">-->
                <!--                  <router-link to="/users-list" class="sidebar-sub-menu-link">-->
                <!--                    Roles-->
                <!--                  </router-link>-->
                <!--                </li>-->
                <!--                <li class="sidebar-sub-menu-item">-->
                <!--                  <router-link to="/lead-details" class="sidebar-sub-menu-link">-->
                <!--                    Roles-->
                <!--                  </router-link>-->
                <!--                </li>-->
                <!--                <li class="sidebar-sub-menu-item">-->
                <!--                  <router-link to="/crm-contacts" class="sidebar-sub-menu-link">-->
                <!--                    Permisos-->
                <!--                  </router-link>-->
                <!--                </li>-->
              </ul>
            </div>
          </div>
        </li>


        <!--                <li-->
        <!--                    class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0"-->
        <!--                >-->
        <!--                  <a-->
        <!--                      href="#"-->
        <!--                      class="accordion-button collapsed rounded-0 shadow-none bg-transparent d-block"-->
        <!--                      data-bs-toggle="collapse"-->
        <!--                      data-bs-target="#sidebarCollapseThree"-->
        <!--                      aria-expanded="false"-->
        <!--                      aria-controls="sidebarCollapseThree"-->
        <!--                  >-->
        <!--                    <i class="flaticon-arroba"></i>-->
        <!--                    <span class="title">Emails</span>-->
        <!--                  </a>-->
        <!--                  <div-->
        <!--                      id="sidebarCollapseThree"-->
        <!--                      class="accordion-collapse collapse"-->
        <!--                      data-bs-parent="#sidebarNavAccordion"-->
        <!--                  >-->
        <!--                    <div class="accordion-body">-->
        <!--                      <ul class="sidebar-sub-menu ps-0 mb-0 list-unstyled">-->
        <!--                        <li class="sidebar-sub-menu-item">-->
        <!--                          <router-link to="/emails" class="sidebar-sub-menu-link">-->
        <!--                            Inbox-->
        <!--                          </router-link>-->
        <!--                        </li>-->
        <!--                        <li class="sidebar-sub-menu-item">-->
        <!--                          <router-link to="/read-email" class="sidebar-sub-menu-link">-->
        <!--                            Read Email-->
        <!--                          </router-link>-->
        <!--                        </li>-->
        <!--                      </ul>-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                </li>-->

        <!--        nomencladores-->
        <!--        <li-->
        <!--            class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0"-->
        <!--        >-->
        <!--          <a-->
        <!--              href="#"-->
        <!--              class="accordion-button collapsed rounded-0 shadow-none bg-transparent d-block"-->
        <!--              data-bs-toggle="collapse"-->
        <!--              data-bs-target="#sidebarCollapseFour"-->
        <!--              aria-expanded="false"-->
        <!--              aria-controls="sidebarCollapseFour"-->
        <!--          >-->
        <!--            <i class="flaticon-trolley-cart"></i>-->
        <!--            <span class="title">Nomencladores</span>-->
        <!--          </a>-->
        <!--          <div-->
        <!--              id="sidebarCollapseFour"-->
        <!--              class="accordion-collapse collapse"-->
        <!--              data-bs-parent="#sidebarNavAccordion"-->
        <!--          >-->
        <!--            <div class="accordion-body">-->
        <!--              <ul class="sidebar-sub-menu ps-0 mb-0 list-unstyled">-->
        <!--                <li class="sidebar-sub-menu-item">-->
        <!--                  <router-link to="/elementosGastos" class="sidebar-sub-menu-link">-->
        <!--                    Elementos de Gastos-->
        <!--                  </router-link>-->
        <!--                </li>-->
        <!--                <li class="sidebar-sub-menu-item">-->
        <!--                  <router-link-->
        <!--                      to="/category-list"-->
        <!--                      class="sidebar-sub-menu-link"-->
        <!--                  >-->
        <!--                    Categorias de Elementos de Gastos-->
        <!--                  </router-link>-->
        <!--                </li>-->
        <!--                <li class="sidebar-sub-menu-item">-->
        <!--                  <router-link-->
        <!--                      to="/type_element"-->
        <!--                      class="sidebar-sub-menu-link"-->
        <!--                  >-->
        <!--                    Tipo de Elementos de Gastos-->
        <!--                  </router-link>-->
        <!--                </li>-->
        <!--                <li class="sidebar-sub-menu-item">-->
        <!--                  <router-link to="/add-product" class="sidebar-sub-menu-link">-->
        <!--                    Tiempo de Elementos de Gastos-->
        <!--                  </router-link>-->
        <!--                </li>-->
        <!--              </ul>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </li>-->


        <!--        <li-->
        <!--            class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0"-->
        <!--        >-->
        <!--          <a-->
        <!--              href="#"-->
        <!--              class="accordion-button collapsed rounded-0 shadow-none bg-transparent d-block"-->
        <!--              data-bs-toggle="collapse"-->
        <!--              data-bs-target="#sidebarCollapseFive"-->
        <!--              aria-expanded="false"-->
        <!--              aria-controls="sidebarCollapseFive"-->
        <!--          >-->
        <!--            <i class="flaticon-magistrate"></i>-->
        <!--            <span class="title">Simulaciones</span>-->
        <!--          </a>-->
        <!--          <div-->
        <!--              id="sidebarCollapseFive"-->
        <!--              class="accordion-collapse collapse"-->
        <!--              data-bs-parent="#sidebarNavAccordion"-->
        <!--          >-->
        <!--            <div class="accordion-body">-->
        <!--              <ul class="sidebar-sub-menu ps-0 mb-0 list-unstyled">-->
        <!--                <li class="sidebar-sub-menu-item">-->
        <!--                  <router-link to="/courses-list" class="sidebar-sub-menu-link">-->
        <!--                    Casas Vinculadas-->
        <!--                  </router-link>-->
        <!--                </li>-->
        <!--                <li class="sidebar-sub-menu-item">-->
        <!--                  <router-link-->
        <!--                      to="/course-details"-->
        <!--                      class="sidebar-sub-menu-link"-->
        <!--                  >-->
        <!--                    Simulaciones por Secciones-->
        <!--                  </router-link>-->
        <!--                </li>-->
        <!--              </ul>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </li>-->
        <!--                <li-->
        <!--                    class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0"-->
        <!--                >-->
        <!--                  <a-->
        <!--                      href="#"-->
        <!--                      class="accordion-button collapsed rounded-0 shadow-none bg-transparent d-block"-->
        <!--                      data-bs-toggle="collapse"-->
        <!--                      data-bs-target="#sidebarCollapseSix"-->
        <!--                      aria-expanded="false"-->
        <!--                      aria-controls="sidebarCollapseSix"-->
        <!--                  >-->
        <!--                    <i class="flaticon-headphones"></i>-->
        <!--                    <span class="title">Support Desk</span>-->
        <!--                  </a>-->
        <!--                  <div-->
        <!--                      id="sidebarCollapseSix"-->
        <!--                      class="accordion-collapse collapse"-->
        <!--                      data-bs-parent="#sidebarNavAccordion"-->
        <!--                  >-->
        <!--                    <div class="accordion-body">-->
        <!--                      <ul class="sidebar-sub-menu ps-0 mb-0 list-unstyled">-->
        <!--                        <li class="sidebar-sub-menu-item">-->
        <!--                          <router-link-->
        <!--                              to="/support-desk-list"-->
        <!--                              class="sidebar-sub-menu-link"-->
        <!--                          >-->
        <!--                            List View-->
        <!--                          </router-link>-->
        <!--                        </li>-->
        <!--                        <li class="sidebar-sub-menu-item">-->
        <!--                          <router-link-->
        <!--                              to="/support-desk-card"-->
        <!--                              class="sidebar-sub-menu-link"-->
        <!--                          >-->
        <!--                            Card View-->
        <!--                          </router-link>-->
        <!--                        </li>-->
        <!--                        <li class="sidebar-sub-menu-item">-->
        <!--                          <router-link to="/contacts" class="sidebar-sub-menu-link">-->
        <!--                            Contacts-->
        <!--                          </router-link>-->
        <!--                        </li>-->
        <!--                        <li class="sidebar-sub-menu-item">-->
        <!--                          <router-link-->
        <!--                              to="/ticket-preview"-->
        <!--                              class="sidebar-sub-menu-link"-->
        <!--                          >-->
        <!--                            Ticket Preview-->
        <!--                          </router-link>-->
        <!--                        </li>-->
        <!--                      </ul>-->
        <!--                    </div>-->
        <!--                  </div>-->
        <!--                </li>-->
        <!--        <li-->
        <!--            class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0"-->
        <!--        >-->
        <!--          <a-->
        <!--              href="#"-->
        <!--              class="accordion-button collapsed rounded-0 shadow-none bg-transparent d-block"-->
        <!--              data-bs-toggle="collapse"-->
        <!--              data-bs-target="#sidebarCollapseSeven"-->
        <!--              aria-expanded="false"-->
        <!--              aria-controls="sidebarCollapseSeven"-->
        <!--          >-->
        <!--            <i class="flaticon-layer-2"></i>-->
        <!--            <span class="title">Comparación y Monitoreo</span>-->
        <!--          </a>-->
        <!--          <div-->
        <!--              id="sidebarCollapseSeven"-->
        <!--              class="accordion-collapse collapse"-->
        <!--              data-bs-parent="#sidebarNavAccordion"-->
        <!--          >-->
        <!--            <div class="accordion-body">-->
        <!--              <ul class="sidebar-sub-menu ps-0 mb-0 list-unstyled">-->
        <!--                <li class="sidebar-sub-menu-item">-->
        <!--                  <router-link-->
        <!--                      to="/projects-list"-->
        <!--                      class="sidebar-sub-menu-link"-->
        <!--                  >-->
        <!--                    Historial de Monitoreos-->
        <!--                  </router-link>-->
        <!--                </li>-->
        <!--                <li class="sidebar-sub-menu-item">-->
        <!--                  <router-link-->
        <!--                      to="/projects-grid"-->
        <!--                      class="sidebar-sub-menu-link"-->
        <!--                  >-->
        <!--                    Ciclo de Comparación-->
        <!--                  </router-link>-->
        <!--                </li>-->
        <!--              </ul>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </li>-->

        <!--        <li-->
        <!--            class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0"-->
        <!--        >-->
        <!--          <a-->
        <!--              href="#"-->
        <!--              class="accordion-button collapsed rounded-0 shadow-none bg-transparent d-block"-->
        <!--              data-bs-toggle="collapse"-->
        <!--              data-bs-target="#sidebarCollapseEight"-->
        <!--              aria-expanded="false"-->
        <!--              aria-controls="sidebarCollapseEight"-->
        <!--          >-->
        <!--            <i class="flaticon-ticket"></i>-->
        <!--            <span class="title">Recordatorios</span>-->
        <!--          </a>-->
        <!--          <div-->
        <!--              id="sidebarCollapseEight"-->
        <!--              class="accordion-collapse collapse"-->
        <!--              data-bs-parent="#sidebarNavAccordion"-->
        <!--          >-->
        <!--            <div class="accordion-body">-->
        <!--              <ul class="sidebar-sub-menu ps-0 mb-0 list-unstyled">-->
        <!--                <li class="sidebar-sub-menu-item">-->
        <!--                  <router-link to="/events" class="sidebar-sub-menu-link">-->
        <!--                    Rutas de Factura-->
        <!--                  </router-link>-->
        <!--                </li>-->
        <!--                <li class="sidebar-sub-menu-item">-->
        <!--                  <router-link-->
        <!--                      to="/event-details"-->
        <!--                      class="sidebar-sub-menu-link"-->
        <!--                  >-->
        <!--                    Listado de Recordatorios Configurados-->
        <!--                  </router-link>-->
        <!--                </li>-->
        <!--              </ul>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </li>-->

        <li
            class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0"
        >
          <a
              href="#"
              class="accordion-button collapsed rounded-0 shadow-none bg-transparent d-block"
              data-bs-toggle="collapse"
              data-bs-target="#sidebarCollapseNine"
              aria-expanded="false"
              aria-controls="sidebarCollapseNine"
          >
            <i class="flaticon-juegos"></i>
            <span class="title">Juegos</span>
          </a>
          <div
              id="sidebarCollapseNine"
              class="accordion-collapse collapse"
              data-bs-parent="#sidebarNavAccordion"
          >
            <div class="accordion-body">
              <ul class="sidebar-sub-menu ps-0 mb-0 list-unstyled">
                <li class="sidebar-sub-menu-item">
                  <router-link
                      to="/game-list"
                      class="sidebar-sub-menu-link bg-transparent"
                  >
                    Gestionar Juego
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link
                      to="/games-user-list"
                      class="sidebar-sub-menu-link bg-transparent"
                  >
                    Gestionar Jugador
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link
                      to="/games-winner-list"
                      class="sidebar-sub-menu-link bg-transparent"
                  >
                    Listado Ganadores
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link
                      to="/levels-list"
                      class="sidebar-sub-menu-link bg-transparent"
                  >
                    Gestionar Niveles
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link
                      to="/questions-list"
                      class="sidebar-sub-menu-link bg-transparent"
                  >
                    Gestionar Preguntas
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link
                      to="/answers-list"
                      class="sidebar-sub-menu-link bg-transparent"
                  >
                    Posibles Respuestas
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>


        <li
            class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0"
        >
          <a
              href="#"
              class="accordion-button collapsed rounded-0 shadow-none bg-transparent d-block"
              data-bs-toggle="collapse"
              data-bs-target="#sidebarCollapseTwelve"
              aria-expanded="false"
              aria-controls="sidebarCollapseTwelve"
          >
            <i class="flaticon-seducativa"></i>
            <span class="title">Secciones Educativas</span>
          </a>
          <div
              id="sidebarCollapseTwelve"
              class="accordion-collapse collapse"
              data-bs-parent="#sidebarNavAccordion"
          >
            <div class="accordion-body">
              <ul class="sidebar-sub-menu ps-0 mb-0 list-unstyled">
                <li class="sidebar-sub-menu-item">
                  <router-link
                      to="/educ-sections-list"
                      class="sidebar-sub-menu-link bg-transparent"
                  >
                    Secciones Educativas
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link
                      to="/topic-list"
                      class="sidebar-sub-menu-link bg-transparent"
                  >
                  Agregar Tópicos
                  </router-link>
                </li>
                <!-- <li class="sidebar-sub-menu-item">
                  <router-link
                      to="/add-topic"
                      class="sidebar-sub-menu-link"
                  >
                    Agregar Tópicos
                  </router-link>
                </li> -->
              </ul>
            </div>
          </div>
        </li>


        <!--        <li-->
        <!--            class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0"-->
        <!--        >-->
        <!--          <a-->
        <!--              href="#"-->
        <!--              class="accordion-button collapsed rounded-0 shadow-none bg-transparent d-block"-->
        <!--              data-bs-toggle="collapse"-->
        <!--              data-bs-target="#sidebarCollapseFourteen"-->
        <!--              aria-expanded="false"-->
        <!--              aria-controls="sidebarCollapseFourteen"-->
        <!--          >-->
        <!--            <i class="flaticon-pie-chart"></i>-->
        <!--            <span class="title">Reportes</span>-->
        <!--          </a>-->
        <!--          <div-->
        <!--              id="sidebarCollapseFourteen"-->
        <!--              class="accordion-collapse collapse"-->
        <!--              data-bs-parent="#sidebarNavAccordion"-->
        <!--          >-->
        <!--            <div class="accordion-body">-->
        <!--              <ul class="sidebar-sub-menu ps-0 mb-0 list-unstyled">-->
        <!--                <li class="sidebar-sub-menu-item">-->
        <!--                  <router-link to="/charts" class="sidebar-sub-menu-link">-->
        <!--                    Consumo Diario-->
        <!--                  </router-link>-->
        <!--                </li>-->
        <!--                <li class="sidebar-sub-menu-item">-->
        <!--                  <router-link to="/mixed-charts" class="sidebar-sub-menu-link">-->
        <!--                    Consumo Semanal-->
        <!--                  </router-link>-->
        <!--                </li>-->
        <!--                <li class="sidebar-sub-menu-item">-->
        <!--                  <router-link to="/charts" class="sidebar-sub-menu-link">-->
        <!--                    Consumo Mensual-->
        <!--                  </router-link>-->
        <!--                </li>-->
        <!--                <li class="sidebar-sub-menu-item">-->
        <!--                  <router-link to="/mixed-charts" class="sidebar-sub-menu-link">-->
        <!--                    Consumo Anual-->
        <!--                  </router-link>-->
        <!--                </li>-->
        <!--              </ul>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </li>-->

        <li class="sidebar-nav-item">
          <router-link to="" class="sidebar-nav-link d-block">
            <i class="flaticon-logout"></i>
            <span class="title" @click.prevent="logout">Cerrar Sesión</span>
          </router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import * as StateStore from "@/utils/store";
import { stateStore } from "@/utils/store";
import Swal from "sweetalert2";

export default defineComponent({
  name: "MainSidebar",
  props: {
    axios: {
      type: [Object, Function],
      required: true,
    },
  },
  setup(props) {
    const stateStoreInstance = stateStore;
    const refreshToken = localStorage.getItem("refreshToken");

    return {
      stateStoreInstance,
      async logout() {
        // Muestra un cuadro de confirmación utilizando SweetAlert2
        const result = await Swal.fire({
          title: "¿Está seguro de que desea cerrar sesión?",
          text: "No podrás deshacer esta acción.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sí, cerrar sesión",
          cancelButtonText: "Cancelar",
        });

        if (result.isConfirmed) {
          console.log("El usuario confirmó: ", refreshToken);

          await StateStore.actions.actionsLogout({
            axios: props.axios,
            refreshToken: refreshToken,
          });

          // Recarga la página después de cerrar sesión
          location.reload();
        } else {
          console.log("El usuario canceló");
        }
      },
    };
  },
});
</script>
