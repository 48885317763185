import {reactive} from "vue";
import Auth from "@/models/Auth"; // Asegúrate de importar tu módulo Auth
import User from "@/models/User";
import Roles from "@/models/Roles";
import ElemetosGastos from "@/models/ElemetosGastos";
import CategoryElemetosGastos from "@/models/CategoryElemetosGastos";
import Section from "@/models/Section";
import Game from "@/models/Game";
import Levels from "@/models/Levels";
import Questions from "@/models/Questions";
import Answers from "@/models/Answers";
import Articles from "@/models/Articles";
import EducationalSections from "@/models/EducationalSections";
import Files from "@/models/Files";

interface StateStore {
    open: boolean;
  onChange: () => void;
  token: string | null;
  tokenAuth: any;
  errorMessage: string;
  currentUser: any;
  userRetrieve: any;
  userId: number;
  newIdUser: any;
    userList: any[];
    userGamerList: any[];
    userWinnerList: any[];
  sectionList: any[];
  sectionRetrieve: any;
  elementosGastosList: any[];
  elementosGastosRetrieve: any;
  categoryElementosGastosList: any[];
  categoryElementosGastosRetrieve: any;
  gameList: any[],
  gameRetrieve: any,
  levelList: any[],
  levelRetrieve: any,
  questionsList: any[],
  questionsRetrieve: any,
    answersList: any[],
    answersRetrieve: any,
    articlesList: any[],
    articlesRetrieve: any,
    educationalSectionsList: any[],
    educationalSectionsRetrieve: any,
  roleList: any[];
  ok: boolean;
}

const stateStore: StateStore = reactive({
  open: false,
  onChange ()  {
    stateStore.open = !stateStore.open
  },
  token: localStorage.getItem("token"),
  tokenAuth: null,
  errorMessage: "",
  currentUser: localStorage.getItem("currentUser"),
  userRetrieve: {},
  userId: 0,
  newIdUser: 0,
  userList: [],
    userGamerList: [],
    userWinnerList: [],
  sectionList: [],
  sectionRetrieve: {},
  elementosGastosList: [],
  elementosGastosRetrieve: {},
  categoryElementosGastosList: [],
  categoryElementosGastosRetrieve: {},
  gameList: [],
  gameRetrieve: {},
  levelList: [],
  levelRetrieve: {},
  questionsList: [],
  questionsRetrieve: {},
    answersList: [],
    answersRetrieve: {},
  roleList: [],
    articlesList: [],
    articlesRetrieve: {},
    educationalSectionsList: [],
    educationalSectionsRetrieve: {},
  ok: false,
});


const actions = {
  actionsSetErrorMessage(payload: any) { // Define el tipo correcto para payload
    mutations.SET_ERROR_MESSAGE(payload);
  },
  async actionsMe(payload: any) { // Define el tipo correcto para payload
    await mutations.ME(payload);
  },
  async actionsCurrentUser(payload: any) { // Define el tipo correcto para axios
    await mutations.CURRENT_USER(payload);
  },
  async actionsLogin(payload: any) {
    await mutations.LOGIN(payload);
  },
    async actionsLogout(payload: any) {
        await mutations.LOGOUT(payload);
    },
 async actionsSetNewIdUser(payload: any) {
    await mutations.SET_NEW_ID_USER(payload);
  },
  async actionsGetAllRoleList(payload: any) {
    await mutations.GET_ALL_ROLE_LIST(payload)
  },
  async actionsGetAllUsersList(payload: any) {
    await mutations.GET_ALL_USERS_LIST(payload)
  },
    async actionsGetAllUsersGamerList(payload: any) {
        await mutations.GET_ALL_USERS_GAMER_LIST(payload)
    },
    async actionsGetAllUsersWinnerList(payload: any) {
        await mutations.GET_ALL_USERS_WINNER_LIST(payload)
    },
  async actionsCreateUser(payload: any) {
    await mutations.CREATE_USER(payload);
  },
  async actionsEditUser(payload: any) {
    await mutations.EDIT_USER(payload);
  },
  async actionsEditPUser(payload: any) {
    await mutations.EDIT_USERP(payload);
  },
  async actionsGetUser(payload: any) {
    await mutations.GET_USER(payload);
  },
  async actionsDeleteUser(payload: any) {
    await mutations.DELETE_USER(payload);
  },
  async actionsChangePassword(payload: any) {
    await mutations.CHANGE_PASSWORD(payload);
  },

  async actionsGetAllElementosList(payload: any) {
    await mutations.GET_ALL_ELEMENTOS_LIST(payload)
  },
  async actionsCreateElementos(payload: any) {
    await mutations.CREATE_ELEMENTOS(payload);
  },
  async actionsEditElementos(payload: any) {
    await mutations.EDIT_ELEMENTOS(payload);
  },
  async actionsGetElementos(payload: any) {
    await mutations.GET_ELEMENTOS(payload);
  },
  async actionsDeleteElementos(payload: any) {
    await mutations.DELETE_ELEMENTOS(payload);
  },

  async actionsGetAllCategoryElementosList(payload: any) {
    await mutations.GET_ALL_CATEGORY_ELEMENTOS_LIST(payload)
  },
  async actionsCreateCategoryElementos(payload: any) {
    await mutations.CREATE_CATEGORY_ELEMENTOS(payload);
  },
  async actionsEditCategoyElementos(payload: any) {
    await mutations.EDIT_CATEGORY_ELEMENTOS(payload);
  },
  async actionsGetCategoryElementos(payload: any) {
    await mutations.GET_CATEGORY_ELEMENTOS(payload);
  },
  async actionsDeleteCategoryElementos(payload: any) {
    await mutations.DELETE_CATEGORY_ELEMENTOS(payload);
  },

  async actionsGetAllSectionList(payload: any) {
    await mutations.GET_ALL_SECTION_LIST(payload)
  },
  async actionsCreateSection(payload: any) {
    await mutations.CREATE_SECTION(payload);
  },
  async actionsEditSection(payload: any) {
    await mutations.EDIT_SECTION(payload);
  },
  async actionsGetSection(payload: any) {
    await mutations.GET_SECTION(payload);
  },
  async actionsDeleteSection(payload: any) {
    await mutations.DELETE_SECTION(payload);
  },

  async actionsGetAllGameList(payload: any) {
    await mutations.GET_ALL_GAME_LIST(payload)
  },
  async actionsCreateGame(payload: any) {
    await mutations.CREATE_GAME(payload);
  },
  async actionsEditGame(payload: any) {
    await mutations.EDIT_GAME(payload);
  },
  async actionsGetGame(payload: any) {
    await mutations.GET_GAME(payload);
  },
  async actionsDeleteGame(payload: any) {
    await mutations.DELETE_GAME(payload);
  },


  async actionsGetAllLevelList(payload: any) {
    await mutations.GET_ALL_LEVEL_LIST(payload)
  },
  async actionsCreateLevel(payload: any) {
    await mutations.CREATE_LEVEL(payload);
  },
  async actionsEditLevel(payload: any) {
    await mutations.EDIT_LEVEL(payload);
  },
  async actionsGetLevel(payload: any) {
    await mutations.GET_LEVEL(payload);
  },
  async actionsDeleteLevel(payload: any) {
    await mutations.DELETE_LEVEL(payload);
  },


  async actionsGetAllQuestionsList(payload: any) {
    await mutations.GET_ALL_QUESTIONS_LIST(payload)
  },
  async actionsCreateQuestions(payload: any) {
    await mutations.CREATE_QUESTIONS(payload);
  },
  async actionsEditQuestions(payload: any) {
    await mutations.EDIT_QUESTIONS(payload);
  },
  async actionsGetQuestions(payload: any) {
    await mutations.GET_QUESTIONS(payload);
  },
  async actionsDeleteQuestions(payload: any) {
    await mutations.DELETE_QUESTIONS(payload);
  },


    async actionsGetAllAnswersList(payload: any) {
        await mutations.GET_ALL_ANSWERS_LIST(payload)
    },
    async actionsCreateAnswers(payload: any) {
        await mutations.CREATE_ANSWERS(payload);
    },
    async actionsEditAnswers(payload: any) {
        await mutations.EDIT_ANSWERS(payload);
    },
    async actionsGetAnswers(payload: any) {
        await mutations.GET_ANSWERS(payload);
    },
    async actionsDeleteAnswers(payload: any) {
        await mutations.DELETE_ANSWERS(payload);
    },


    async actionsGetAllArticlesList(payload: any) {
        await mutations.GET_ALL_ARTICLES_LIST(payload)
    },
    async actionsCreateArticles(payload: any) {
        await mutations.CREATE_ARTICLES(payload);
    },
    async actionsEditArticles(payload: any) {
        await mutations.EDIT_ARTICLES(payload);
    },
    async actionsGetArticles(payload: any) {
        await mutations.GET_ARTICLES(payload);
    },
    async actionsDeleteArticles(payload: any) {
        await mutations.DELETE_ARTICLES(payload);
    },
    async actionsCreateFiles(payload: any) {
      await mutations.CREATE_FILES(payload);
  },


    async actionsGetAllEducSectionsList(payload: any) {
        await mutations.GET_ALL_EDUCATIONAL_SECTIONS_LIST(payload)
    },
    async actionsCreateEducSections(payload: any) {
        await mutations.CREATE_EDUCATIONAL_SECTIONS(payload);
    },
    async actionsEditEducSections(payload: any) {
        await mutations.EDIT_EDUCATIONAL_SECTIONS(payload);
    },
    async actionsGetEducSections(payload: any) {
        await mutations.GET_EDUCATIONAL_SECTIONS(payload);
    },
    async actionsDeleteEducSections(payload: any) {
        await mutations.DELETE_EDUCATIONAL_SECTIONS(payload);
    },
};

const mutations = {
  SET_ERROR_MESSAGE(payload: string) { // Define el tipo correcto para payload
    stateStore.errorMessage = payload;
  },
  async LOGIN(payload: any) { // Define el tipo correcto para payload
    stateStore.tokenAuth = await Auth.login(
        payload.axios,
        payload.username,
        payload.password
    );
    stateStore.token = stateStore.tokenAuth?.token;
    if (stateStore.token !== null
        && stateStore.token !== undefined
        && stateStore.token !== 'null'
        && stateStore.token !== 'null-new'
    ) {
      localStorage.setItem("token", stateStore.token);
      localStorage.setItem("userId", stateStore.tokenAuth.user.pk);
      localStorage.setItem("refreshToken", stateStore.tokenAuth.refreshToken);
      stateStore.errorMessage = "";
      return true;
    } else {
      if (
          localStorage.getItem("token") === null ||
          localStorage.getItem("token") === "null"
      ) {
        localStorage.setItem("token", "null-new");
        stateStore.token = "null-new";
        return false;

      } else {
        localStorage.setItem("token", "null");
        stateStore.token = null;
        return false;

      }
    }
  },
    async LOGOUT(payload: any) {
        // Opcional: Puedes agregar una llamada a una función de logout en tu API si es necesario
        stateStore.ok = await Auth.logout(payload.axios, payload.refreshToken);
        if (stateStore.ok) {
            // Elimina los tokens del almacenamiento local
            localStorage.removeItem("token");
            localStorage.removeItem("currentUser");
            localStorage.removeItem("userId");
            localStorage.removeItem("refreshToken");

            // Restablece los valores en el stateStore
            stateStore.token = null;
            stateStore.tokenAuth = null;
            stateStore.errorMessage = "";
            stateStore.currentUser = null;
        }
    },
  async ME(payload: any) { // Define el tipo correcto para payload
    stateStore.currentUser = await Auth.me(payload.axios);
    stateStore.userId = stateStore.currentUser.id;
    localStorage.setItem("userId", stateStore.userId.toString());
    localStorage.setItem("currentUser", JSON.stringify(stateStore.currentUser));
  },
  async CURRENT_USER(payload: any) { // Define el tipo correcto para axios
    if (stateStore.userId > 0) {
      stateStore.currentUser = await User.retrieve(
          payload.axios,
          localStorage.getItem("userId")
      );
      localStorage.setItem("currentUser", JSON.stringify(stateStore.currentUser));
    }
  },
  async SET_NEW_ID_USER(payload: string) {
    stateStore.newIdUser = payload;
  },
  async GET_ALL_ROLE_LIST(payload: any) {
    stateStore.roleList = await Roles.all(
        payload.axios
    )
  },
  async GET_ALL_USERS_LIST(payload: any) {
    stateStore.userList = await User.all(
        payload.axios
    )
  },
    async GET_ALL_USERS_GAMER_LIST(payload: any) {
        stateStore.userGamerList = await User.gamer(
            payload.axios
        )
    },
    async GET_ALL_USERS_WINNER_LIST(payload: any) {
        stateStore.userWinnerList = await User.winner(
            payload.axios
        )
    },
  async CREATE_USER(payload: any) {
    stateStore.ok = await User.create(
        payload.axios,
        payload.password,
        payload.username,
        payload.email,
        payload.firstName,
        payload.lastName,
        payload.roles,
        payload.phone,
    )
  },
  async EDIT_USER(payload: any) {
    stateStore.ok = await User.edit(
        payload.axios,
        payload.id,
        payload.username,
        payload.email,
        payload.firstName,
        payload.lastName,
        payload.roles,
        payload.phone
        
    )
  },
  async EDIT_USERP(payload: any) {
    stateStore.ok = await User.editP(
        payload.axios,
        payload.id,
        payload.username,
        payload.email,
        payload.firstName,
        payload.lastName,
        payload.roles,
        payload.phone,
        payload.password
    )
  },
  async GET_USER(payload: any) { // Define el tipo correcto para axios
    stateStore.userRetrieve = await User.retrieve(
        payload.axios,
        payload.id,
    );
  },
  async DELETE_USER(payload: any) {
    stateStore.ok = await User.delete(
        payload.axios,
        payload.id,
    )
  },
  async CHANGE_PASSWORD(payload: any) {
    stateStore.ok = await User.changePassword(
        payload.axios,
        payload.oldPassword,
        payload.newPassword1,
        payload.newPassword2,
        
    )
  },

  async GET_ALL_ELEMENTOS_LIST(payload: any) {
    stateStore.elementosGastosList = await ElemetosGastos.all(
        payload.axios
    )
  },
  async CREATE_ELEMENTOS(payload: any) {
    stateStore.ok = await ElemetosGastos.create(payload)
  },
  async EDIT_ELEMENTOS(payload: any) {
    stateStore.ok = await ElemetosGastos.edit(payload)
  },
  async GET_ELEMENTOS(payload: any) { // Define el tipo correcto para axios
    stateStore.elementosGastosRetrieve = await ElemetosGastos.retrieve(
        payload.axios,
        payload.id,
    );
  },
  async DELETE_ELEMENTOS(payload: any) {
    stateStore.ok = await ElemetosGastos.delete(
        payload.axios,
        payload.id,
    )
  },

  async GET_ALL_CATEGORY_ELEMENTOS_LIST(payload: any) {
    stateStore.categoryElementosGastosList = await CategoryElemetosGastos.all(
        payload.axios
    )
  },
  async CREATE_CATEGORY_ELEMENTOS(payload: any) {
    stateStore.ok = await CategoryElemetosGastos.create(
        payload.axios,
        payload.name,
        payload.section,
    )
  },
  async EDIT_CATEGORY_ELEMENTOS(payload: any) {
    stateStore.ok = await CategoryElemetosGastos.edit(
        payload.axios,
        payload.id,
        payload.name,
        payload.section
    )
  },
  async GET_CATEGORY_ELEMENTOS(payload: any) { // Define el tipo correcto para axios
    stateStore.categoryElementosGastosRetrieve = await CategoryElemetosGastos.retrieve(
        payload.axios,
        payload.id,
    );
  },
  async DELETE_CATEGORY_ELEMENTOS(payload: any) {
    stateStore.ok = await CategoryElemetosGastos.delete(
        payload.axios,
        payload.id,
    )
  },


  async GET_ALL_SECTION_LIST(payload: any) {
    stateStore.sectionList = await Section.all(
        payload.axios
    )
  },
  async CREATE_SECTION(payload: any) {
    stateStore.ok = await Section.create(
        payload.axios,
        payload.password,
        payload.username,
        payload.email,
        payload.firstName,
        payload.lastName,
        payload.roles,
        payload.phone,
    )
  },
  async EDIT_SECTION(payload: any) {
    stateStore.ok = await Section.edit(
        payload.axios,
        payload.id,
        payload.username,
        payload.email,
        payload.firstName,
        payload.lastName,
        payload.roles,
        payload.phone,
    )
  },
  async GET_SECTION(payload: any) { // Define el tipo correcto para axios
    stateStore.sectionRetrieve = await Section.retrieve(
        payload.axios,
        payload.id,
    );
  },
  async DELETE_SECTION(payload: any) {
    stateStore.ok = await CategoryElemetosGastos.delete(
        payload.axios,
        payload.id,
    )
  },

  async GET_ALL_GAME_LIST(payload: any) {
    stateStore.gameList = await Game.all(
        payload.axios
    )
  },
  async CREATE_GAME(payload: any) {
    stateStore.ok = await Game.create(
        payload.axios,
        payload.name,
    )
  },
  async EDIT_GAME(payload: any) {
    stateStore.ok = await Game.edit(
        payload.axios,
        payload.id,
        payload.name,
    )
  },
  async GET_GAME(payload: any) { // Define el tipo correcto para axios
    stateStore.gameRetrieve = await Game.retrieve(
        payload.axios,
        payload.id,
    );
  },
  async DELETE_GAME(payload: any) {
    stateStore.ok = await Game.delete(
        payload.axios,
        payload.id,
    )
  },

  async GET_ALL_LEVEL_LIST(payload: any) {
    stateStore.levelList = await Levels.all(
        payload.axios
    )
  },
  async CREATE_LEVEL(payload: any) {
    stateStore.ok = await Levels.create(
        payload.axios,
        payload.name,
        payload.game,
    )
  },
  async EDIT_LEVEL(payload: any) {
    stateStore.ok = await Levels.edit(
        payload.axios,
        payload.id,
        payload.name,
        payload.game,
    )
  },
  async GET_LEVEL(payload: any) { // Define el tipo correcto para axios
    stateStore.levelRetrieve = await Levels.retrieve(
        payload.axios,
        payload.id,
    );
  },
  async DELETE_LEVEL(payload: any) {
    stateStore.ok = await Levels.delete(
        payload.axios,
        payload.id,
    )
  },

  async GET_ALL_QUESTIONS_LIST(payload: any) {
    stateStore.questionsList = await Questions.all(
        payload.axios
    )
  },
  async CREATE_QUESTIONS(payload: any) {
    stateStore.ok = await Questions.create(
        payload.axios,
        payload.name,
        payload.point,
        payload.level,
    )
  },
  async EDIT_QUESTIONS(payload: any) {
    stateStore.ok = await Questions.edit(
        payload.axios,
        payload.id,
        payload.name,
        payload.point,
        payload.level,
    )
  },
  async GET_QUESTIONS(payload: any) { // Define el tipo correcto para axios
    stateStore.questionsRetrieve = await Questions.retrieve(
        payload.axios,
        payload.id,
    );
  },
  async DELETE_QUESTIONS(payload: any) {
    stateStore.ok = await Questions.delete(
        payload.axios,
        payload.id,
    )
  },

    async GET_ALL_ANSWERS_LIST(payload: any) {
        stateStore.answersList = await Answers.all(
            payload.axios
        )
    },
    async CREATE_ANSWERS(payload: any) {
        stateStore.ok = await Answers.create(
            payload.axios,
            payload.name,
            payload.value,
            payload.question,
            payload.correct,
        )
    },
    async EDIT_ANSWERS(payload: any) {
        stateStore.ok = await Answers.edit(
            payload.axios,
            payload.id,
            payload.name,
            payload.value,
            payload.question,
            payload.correct,
        )
    },
    async GET_ANSWERS(payload: any) { // Define el tipo correcto para axios
        stateStore.answersRetrieve = await Answers.retrieve(
            payload.axios,
            payload.id,
        );
    },
    async DELETE_ANSWERS(payload: any) {
        stateStore.ok = await Answers.delete(
            payload.axios,
            payload.id,
        )
    },

    async GET_ALL_ARTICLES_LIST(payload: any) {
        stateStore.articlesList = await Articles.all(
            payload.axios
        )
    },
    async CREATE_ARTICLES(payload: any) {
        stateStore.ok = await Articles.create(
            payload.axios,
            payload.name,
            payload.text,
            payload.educationalSections,
            payload.files,
        )
    },
    async EDIT_ARTICLES(payload: any) {
        stateStore.ok = await Articles.edit(
            payload.axios,
            payload.id,
            payload.name,
            payload.text,
            payload.educationalSections,
            payload.files,
        )
    },
    async GET_ARTICLES(payload: any) { // Define el tipo correcto para axios
        stateStore.articlesRetrieve = await Articles.retrieve(
            payload.axios,
            payload.id,
        );
    },
    async DELETE_ARTICLES(payload: any) {
        stateStore.ok = await Articles.delete(
            payload.axios,
            payload.id,
        )
    },
    async CREATE_FILES(payload: any) {
      stateStore.ok = await Files.createMultiple(
          payload.axios,
          payload.files,
      )
  },


    async GET_ALL_EDUCATIONAL_SECTIONS_LIST(payload: any) {
        stateStore.educationalSectionsList = await EducationalSections.all(
            payload.axios
        )
    },
    async CREATE_EDUCATIONAL_SECTIONS(payload: any) {
        stateStore.ok = await EducationalSections.create(
            payload.axios,
            payload.name,
        )
    },
    async EDIT_EDUCATIONAL_SECTIONS(payload: any) {
        stateStore.ok = await EducationalSections.edit(
            payload.axios,
            payload.id,
            payload.name,
        )
    },
    async GET_EDUCATIONAL_SECTIONS(payload: any) { // Define el tipo correcto para axios
        stateStore.educationalSectionsRetrieve = await EducationalSections.retrieve(
            payload.axios,
            payload.id,
        );
    },
    async DELETE_EDUCATIONAL_SECTIONS(payload: any) {
        stateStore.ok = await EducationalSections.delete(
            payload.axios,
            payload.id,
        )
    },
};

const getters = {
  getErrorMessage: (stateStore: { errorMessage: string }) => {
    return stateStore.errorMessage;
  },
  getCurrentUser: (stateStore: { currentUser: string }) => {
    return stateStore.currentUser;
  },
  getToken: (stateStore: { token: string }) => {
    return localStorage.getItem("token");
  },
  getUsersList: () => {
    return stateStore.userList;
  },
    getUsersGamerList: () => {
        return stateStore.userGamerList;
    },
    getUsersWinnerList: () => {
        return stateStore.userWinnerList;
    },
  getRoleList: () => {
    return stateStore.roleList;
  },
  getSectionList: () => {
    return stateStore.sectionList;
  },
  getUserRetrieve: () => {
    return stateStore.userRetrieve;
  },
  getElementosGastosList: () => {
    return stateStore.elementosGastosList;
  },
  getCategoryElementosGastosList: () => {
    return stateStore.categoryElementosGastosList;
  },
  getCategoryElementosRetrieve: () => {
    return stateStore.categoryElementosGastosRetrieve;
  },
  getGameList: () => {
    return stateStore.gameList;
  },
  getGameRetrieve: () => {
    return stateStore.gameRetrieve;
  },
  getLevelList: () => {
    return stateStore.levelList;
  },
  getLevelRetrieve: () => {
    return stateStore.levelRetrieve;
  },
  getQuestionsList: () => {
    return stateStore.questionsList;
  },
  getQuestionsRetrieve: () => {
    return stateStore.questionsRetrieve;
  },
    getAnswersList: () => {
        return stateStore.answersList;
    },
    getAnswerRetrieve: () => {
        return stateStore.answersRetrieve;
    },
    getArticlesList: () => {
        return stateStore.articlesList;
    },
    getArticlesRetrieve: () => {
        return stateStore.articlesRetrieve;
    },
    getEducationalSectionsList: () => {
        return stateStore.educationalSectionsList;
    },
    getEducationalSectionsRetrieve: () => {
        return stateStore.educationalSectionsRetrieve;
    },
};
export { stateStore, actions, mutations, getters };