<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white d-md-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <!--      <form class="search-box position-relative">-->
      <!--        <input-->
      <!--          type="text"-->
      <!--          class="form-control shadow-none text-black rounded-0 border-0"-->
      <!--          placeholder="Search product"-->
      <!--        />-->
      <!--        <button-->
      <!--          type="submit"-->
      <!--          class="bg-transparent text-primary transition p-0 border-0"-->
      <!--        >-->
      <!--          <i class="flaticon-search-interface-symbol"></i>-->
      <!--        </button>-->
      <!--      </form>-->
      <div class="d-sm-flex align-items-center">
        <a
            class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0 text-decoration-none"
            href="#"
            data-bs-toggle="modal"
            data-bs-target="#createEditCategoryModal"
            @click.prevent="handleModalClosed"
        >
          Agregar Categoria
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
        </a>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                Nombre
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Sección
              </th>
              <th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                Acción
              </th>
            </tr>
          </thead>
          <tbody>
          <tr v-for="item in items" :key="item.id">
              <th class="shadow-none fw-medium text-black title ps-0">
                <div class="d-flex align-items-center">
                  <router-link
                    to="/product-details"
                    class="d-flex align-items-center text-decoration-none text-black ms-8 fs-md-15 fs-lg-16"
                  >
                    <img
                      src="../../../assets/images/products/product17.jpg"
                      class="me-15"
                      width="44"
                      alt="product"
                    />
                    {{ item.name }}
                  </router-link>
                </div>
              </th>
              <td class="shadow-none lh-1 fw-medium text-paragraph">
                {{ item.section.name }}
              </td>
            <td class="action-link">
              <router-link :to="`/profile/${item.id}`">
                <i class="ph ph-eye me-2 action-link" style="font-size: 24px;"/>
              </router-link>
              <i
                  class="ph ph-pencil action-link"
                  style="font-size: 24px;"
                  data-bs-toggle="modal"
                  data-bs-target="#createEditCategoryModal"
                  @click="openModal(item)"
              />
              <i
                  class="ph ph-trash ms-2 action-link"
                  style="font-size: 24px;"
                  @click="deleteUser(item.id)"
              />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="pagination-area d-md-flex mb-25 justify-content-between align-items-center">
        <p class="mb-0 text-paragraph">
          Showing <span class="fw-bold">{{ paginatedItems.length }}</span> out of
          <span class="fw-bold">{{ items.length }}</span> results
        </p>
        <nav class="mt-15 mt-md-0">
          <ul class="pagination mb-0">
            <li class="page-item">
              <a class="page-link" @click.prevent="goToPreviousPage" aria-label="Previous">
                <i class="flaticon-chevron-1"></i>
              </a>
            </li>
            <li class="page-item" v-for="page in totalPages" :key="page">
              <a class="page-link" @click.prevent="goToPage(page)">{{ page }}</a>
            </li>
            <li class="page-item">
              <a class="page-link" @click.prevent="goToNextPage" aria-label="Next">
                <i class="flaticon-chevron"></i>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
  <CreateEditCategoryModal
      :axios="axios"
      :id="selectedItem?.id"
      :no-close-on-backdrop="true"
      :title="title"
      @modal-closed="handleModalClosed"
  />
</template>

<script>
import * as StateStore from "@/utils/store";
import CreateEditCategoryModal from "@/components/Ecommerce/AddCategory/CreateEditCategoryModal.vue";
import {computed} from "vue";

export default {
  name: "CategoryList",
  components: {CreateEditCategoryModal},
  props: {
    axios: {
      type: [Object, Function],
      required: true
    }
  },
  data() {
    return {
      title: computed(() => this.selectedItem === null ? 'Crear Nueva ' : 'Editar '),
      selectedItem: null,
      itemsPerPage: 10,
      currentPage: 1,
      items: []
    }
  },
  computed: {
    paginatedItems() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.items.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.items.length / this.itemsPerPage);
    }
  },
  methods: {
    handleModalClosed() {
      this.selectedItem = null;
      // console.log("selectedItem.value: ", selectedItem.value);

    },
    openModal(item) {
      this.selectedItem = item;
      console.log("selectedItem: ", this.selectedItem);

    },
    async deleteUser(id) {
      if (confirm("¿Está seguro de que desea eliminar el la categoria?")) {
        console.log('El usuario confirmó la eliminación');
        await StateStore.actions.actionsDeleteCategoryElementos({'axios': this.axios, 'id': id});
        location.reload();
      } else {
        console.log('El usuario canceló la eliminación');
      }
    },
    goToPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    goToPage(page) {
      this.currentPage = page;
    },
    goToNextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    async getAllitems() {
      await StateStore.actions.actionsGetAllCategoryElementosList({'axios': this.axios});
      this.items = StateStore.getters.getCategoryElementosGastosList();
    }
  },
  async created() {
    await this.getAllitems();
    console.log("items: ", this.items);
  },
};
</script>