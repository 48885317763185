import {AxiosInstance} from 'axios'; // Importa axios
import * as StateStore from "@/utils/store"

export default class User {
    static async retrieve(axios: AxiosInstance, id: number | string | null) {
        const response = await axios.post(
                '/graphql/',
                `query{
                        userRetrieve(id: ${id}) {
                          id
                          username
                          email
                          firstName
                          lastName
                          phone
                          roles{
                            id
                            name
                          }
                          profile{
                              id
                              gamer
                              winner
                          }
                        }
                      }`,
                {
                    headers: {
                        "Content-Type": "application/graphql",
                    },
                }
            ).catch(function (error) {
            if (error.response) {
                console.log("primer strike");
                return Promise.resolve(null);
            } else if (error.request) {
                console.log("segundo strike");
                return Promise.resolve(null);
            }
        })
        if (response) {
            if (
                response.data.data.userRetrieve
            ) {
                response.data.data.userRetrieve.compressed = {
                    roles: response.data.data.userRetrieve.roles,
                };
            }

            response.data.data.userRetrieve.roles = response.data.data.userRetrieve?.roles?.id

            return response.data.data.userRetrieve;
        }
    }

    static async all(axios: AxiosInstance) {
        const response = await axios?.post(
            "/graphql/",
            `query{
                userList{
                          id
                          username
                          email
                          firstName
                          lastName
                          phone
                          roles{
                            id
                            name
                          }
                          profile{
                              id
                              gamer
                              winner
                          }
                }
              }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        );
        console.log("usuarios: ", response.data.data?.userList);

        // const usersList = response.data.data?.userList;
        //
        // for(let i = 0; i < usersList.length; i++) {
        //     // usersList[i].roles = usersList[i].roles.map(node => node.id);
        //     usersList[i].roles = usersList[i].roles.id;
        // }
        return response.data.data?.userList;
    }


    static async gamer(axios: AxiosInstance) {
        const response = await axios?.post(
            "/graphql/",
            `query{
                userGamerList{
                          id
                          username
                          email
                          firstName
                          lastName
                          phone
                          roles{
                            id
                            name
                          }
                          profile{
                              id
                              gamer
                              winner
                          }
                }
              }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        );
        console.log("userGamerList: ", response.data.data?.userGamerList);

        // const usersList = response.data.data?.userList;
        //
        // for(let i = 0; i < usersList.length; i++) {
        //     // usersList[i].roles = usersList[i].roles.map(node => node.id);
        //     usersList[i].roles = usersList[i].roles.id;
        // }
        return response.data.data?.userGamerList;
    }

    static async winner(axios: AxiosInstance) {
        const response = await axios?.post(
            "/graphql/",
            `query{
                  userWinnerList{
                    id
                    email
                    username
                    firstName
                    lastName
                    phone
                    premio{
                      id
                      name
                      state{
                        id
                        name
                        citys{
                          id
                          name
                          state{
                            id
                            name
                          }
                        }
                      }
                      status
                      
                    }
                    roles{
                      id
                      name
                    }
                    profile{
                      id
                      avatar
                      gamer
                      winner
                      progress{
                        id
                        game{
                          id
                          name
                        }
                      }
                    }
                    
                  }
                }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        );
        console.log("userWinnerList: ", response.data?.data?.userWinnerList);

        // const usersList = response.data.data?.userList;
        //
        // for(let i = 0; i < usersList.length; i++) {
        //     // usersList[i].roles = usersList[i].roles.map(node => node.id);
        //     usersList[i].roles = usersList[i].roles.id;
        // }
        return response.data?.data?.userWinnerList;
    }


    static async delete(axios: AxiosInstance, id: number) {
        const response = await axios.post(
            "/graphql/",
            {
                query: `mutation deleteUser ($id: ID!){
                      deleteUser(id: $id){
                        ok
                      }
                    }`,
                variables: {
                    id: id,
                },
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        return response.data.data.deleteUser.ok;
    }

    static async create(
        axios: AxiosInstance,
        password: string,
        username: string,
        email: string,
        firstName: string,
        lastName: string,
        roles: number,
        phone: string,
    ) {
        const response = await axios?.post(
            "/graphql/",
            `mutation{
                    createUser (
                    newCustomuser: {
                      password: "${password}",
                      username: "${username}",
                      email: "${email}",
                      firstName: "${firstName}",
                      lastName: "${lastName}",
                      roles: ${roles},
                      phone: "${phone}",
                      }){
                        ok
                        errors{
                          field
                          messages
                         }
                        customuser{
                          id
                        }
                       }
                  }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        ).catch(function (error) {
            if (error.response) {
                console.log("primer strike");
                return Promise.resolve(null);
            } else if (error.request) {
                console.log("segundo strike");
                return Promise.resolve(null);
            }
        });
        if (response && response.data) {
            if (response.data.data.createUser.ok) {
                await StateStore.actions.actionsSetNewIdUser(response.data.data.createUser.customuser.id);
                return response.data.data.createUser.ok;
            } else if (response.data.data.createUser.errors) {
                const errores = response.data.data.createUser.errors;
                const mensajes: string[] = [];

                for (let i = 0; i < errores.length; i++) {
                    const error = errores[i];
                    for (let j = 0; j < error.messages.length; j++) {
                        mensajes.push(error.messages[j]);
                    }
                }
                console.log("lo que tenemos: ", response.data.data.createUser.errors);
                StateStore.actions.actionsSetErrorMessage(mensajes);
                return null;
            } else if (response.data.errors) {
                console.log("lo que tenemos: ", response.data.errors);
                StateStore.actions.actionsSetErrorMessage(response.data.errors);
                return null;
            }
        } else {
            return null;
        }
    }

    static async edit(
        axios: AxiosInstance,
        id: number,
        username: string,
        email: string,
        firstName: string,
        lastName: string,
        roles: number,
        phone: string,
        
    ) {
        const response = await axios?.post(
            "/graphql/",
            `mutation{
                    updateUser (
                    newCustomuser: {
                      id: ${id},
                      username: "${username}",
                      email: "${email}",
                      firstName: "${firstName}",
                      lastName: "${lastName}",
                      roles: ${roles},
                      phone: "${phone}",                     
                      }){
                        ok
                        errors{
                          field
                          messages
                         }
                        customuser{
                          id
                        }
                       }
                  }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        ).catch(function (error) {
            if (error.response) {
                console.log("primer strike");
                return Promise.resolve(null);
            } else if (error.request) {
                console.log("segundo strike");
                return Promise.resolve(null);
            }
        });
        if (response && response.data) {
            if (response.data.data.updateUser.ok) {
                return response.data.data.updateUser.ok;
            } else if (response.data.data.updateUser.errors) {
                const errores = response.data.data.updateUser.errors;
                const mensajes: string[] = [];

                for (let i = 0; i < errores.length; i++) {
                    const error = errores[i];
                    for (let j = 0; j < error.messages.length; j++) {
                        mensajes.push(error.messages[j]);
                    }
                }
                console.log("lo que tenemos: ", response.data.data.updateUser.errors);
                StateStore.actions.actionsSetErrorMessage(mensajes);
                return null;
            } else if (response.data.errors) {
                console.log("lo que tenemos: ", response.data.errors);
                StateStore.actions.actionsSetErrorMessage(response.data.errors);
                return null;
            }
        } else {
            return null;
        }
    }
    static async editP(
        axios: AxiosInstance,
        id: number,
        username: string,
        email: string,
        firstName: string,
        lastName: string,
        roles: number,
        phone: string,
        password: string
    ) {
        const response = await axios?.post(
            "/graphql/",
            `mutation{
                    updateUser (
                    newCustomuser: {
                      id: ${id},
                      username: "${username}",
                      email: "${email}",
                      firstName: "${firstName}",
                      lastName: "${lastName}",
                      roles: ${roles},
                      phone: "${phone}",
                      password: "${password}"
                      }){
                        ok
                        errors{
                          field
                          messages
                         }
                        customuser{
                          id
                        }
                       }
                  }`,
            {
                headers: {
                    "Content-Type": "application/graphql",
                },
            }
        ).catch(function (error) {
            if (error.response) {
                console.log("primer strike");
                return Promise.resolve(null);
            } else if (error.request) {
                console.log("segundo strike");
                return Promise.resolve(null);
            }
        });
        if (response && response.data) {
            if (response.data.data.updateUser.ok) {
                return response.data.data.updateUser.ok;
            } else if (response.data.data.updateUser.errors) {
                const errores = response.data.data.updateUser.errors;
                const mensajes: string[] = [];

                for (let i = 0; i < errores.length; i++) {
                    const error = errores[i];
                    for (let j = 0; j < error.messages.length; j++) {
                        mensajes.push(error.messages[j]);
                    }
                }
                console.log("lo que tenemos: ", response.data.data.updateUser.errors);
                StateStore.actions.actionsSetErrorMessage(mensajes);
                return null;
            } else if (response.data.errors) {
                console.log("lo que tenemos: ", response.data.errors);
                StateStore.actions.actionsSetErrorMessage(response.data.errors);
                return null;
            }
        } else {
            return null;
        }
    }

    static async changePassword(
    axios: AxiosInstance,
    oldPassword: string,
    newPassword1: string,
    newPassword2: string
) {
    const response = await axios?.post(
        "/graphql/",
        `mutation {
            passwordChange(
                oldPassword: "${oldPassword}",
                newPassword1: "${newPassword1}",
                newPassword2: "${newPassword2}"
            ) {
                ok
                errors {
                    field
                    messages
                }
            }
        }`,
        {
            headers: {
                "Content-Type": "application/graphql",
            },
        }
    ).catch(function (error) {
        if (error.response) {
            console.log("primer strike");
            return Promise.resolve(null);
        } else if (error.request) {
            console.log("segundo strike");
            return Promise.resolve(null);
        }
    });

    if (response && response.data) {
        if (response.data.data.passwordChange.ok) {
            return response.data.data.passwordChange.ok;
        } else if (response.data.data.passwordChange.errors) {
            const errores = response.data.data.passwordChange.errors;
            const mensajes: string[] = [];

            for (let i = 0; i < errores.length; i++) {
                const error = errores[i];
                for (let j = 0; j < error.messages.length; j++) {
                    mensajes.push(error.messages[j]);
                }
            }
            console.log("Errores encontrados: ", response.data.data.passwordChange.errors);
            StateStore.actions.actionsSetErrorMessage(mensajes);
            return null;
        } else if (response.data.errors) {
            console.log("Errores encontrados: ", response.data.errors);
            StateStore.actions.actionsSetErrorMessage(response.data.errors);
            return null;
        }
    } else {
        return null;
    }
}

}
