<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
        class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <a
            class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0 text-decoration-none"
            href="#"
            data-bs-toggle="modal"
            data-bs-target="#createNewQuestionModal"
            @click.prevent="handleModalClosed"
        >
          Agregar Pregunta
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
        </a>
        <!--        <button-->
        <!--            class="default-outline-btn position-relative transition fw-medium text-black pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-transparent fs-md-15 fs-lg-16 d-inline-block mb-10 mb-lg-0"-->
        <!--            type="button"-->
        <!--        >-->
        <!--          Export-->
        <!--          <i class="flaticon-file-1 position-relative ms-5 top-2 fs-15"></i>-->
        <!--        </button>-->
      </div>
      <div class="d-flex align-items-center">
        <form class="search-box position-relative me-15" @submit.prevent="filterResults">
          <input
              type="text"
              v-model="searchQuery"
              class="form-control shadow-none text-black rounded-0 border-0"
              placeholder="Buscar"
          />
          <button
              type="submit"
              class="bg-transparent text-primary transition p-0 border-0"
          >
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
        <button
            class="dot-btn lh-1 position-relative top-3 bg-transparent border-0 shadow-none p-0 transition d-inline-block"
            type="button"
        >
          <i class="flaticon-dots"></i>
        </button>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
          <tr>
            <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Pregunta
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Nivel
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Juego
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              Puntos
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
            >
              ACCIONES
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(question, index) in paginatedItems" :key="index">
            <th
                class="shadow-none lh-1 fw-medium text-black-emphasis title ps-0"
            >
              <div class="d-flex align-items-center">

                <div class="d-flex align-items-center ms-5 fs-md-15 fs-lg-16 long-text">
                  {{ question.name }}
                </div>
              </div>
            </th>
            <th
                class="shadow-none lh-1 fw-medium text-black-emphasis title ps-0"
            >
              <div class="d-flex align-items-center">

                <div class="d-flex align-items-center ms-5 fs-md-15 fs-lg-16">
                  {{ question.level?.name }}
                </div>
              </div>
            </th>
            <th
                class="shadow-none lh-1 fw-medium text-black-emphasis title ps-0"
            >
              <div class="d-flex align-items-center">

                <div class="d-flex align-items-center ms-5 fs-md-15 fs-lg-16">
                  {{ question.level?.game?.name }}
                </div>
              </div>
            </th>
            <th
                class="shadow-none lh-1 fw-medium text-black-emphasis title ps-0"
            >
              <div class="d-flex align-items-center">

                <div class="d-flex align-items-center ms-5 fs-md-15 fs-lg-16">
                  {{ question.point }}
                </div>
              </div>
            </th>
            <td class="action-link">
              <!--              <router-link :to="`/profile/${game.id}`">-->
              <!--                <i class="ph ph-eye me-2 action-link" style="font-size: 24px;"/>-->
              <!--              </router-link>-->
              <i
                  class="ph ph-question action-link cursor-pointer"
                  color="blue"
                  title="Asignar Respuesta"
                  style="font-size: 24px;"
                 
                  @click="openModalRespuesta($event,question)"
              />
              <i
                  class="ph ph-pencil action-link cursor-pointer"
                  style="font-size: 24px;"
                  title="Editar Pregunta"
                  data-bs-toggle="modal"
                  data-bs-target="#createNewQuestionModal"
                  @click="openModal(question)"
              />
              <i
                  class="ph ph-trash ms-2 action-link cursor-pointer"
                  title="Eliminar Pregunta"
                  style="font-size: 24px;"
                  @click="deleteQuestion(question.id)"
              />
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="pagination-area d-md-flex mb-25 justify-content-between align-items-center">
      <p class="ms-20 mb-0 text-paragraph">
        Mostrando <span class="fw-bold">{{ paginatedItems.length }}</span> de
        <span class="fw-bold">{{ items.length }}</span> Resultados
      </p>
      <nav class="mt-15 mt-md-0">
        <ul class="pagination mb-0">
          <li class="page-item">
            <a class="page-link" @click.prevent="goToPreviousPage" aria-label="Previous">
              <i class="flaticon-chevron-1"></i>
            </a>
          </li>
          <li class="page-item" v-for="page in totalPages" :key="page">
            <a class="page-link" @click.prevent="goToPage(page)">{{ page }}</a>
          </li>
          <li class="page-item">
            <a class="page-link" @click.prevent="goToNextPage" aria-label="Next">
              <i class="flaticon-chevron"></i>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
  <CreateNewQuestionsModal
      :axios="axios"
      :id="selectedItem?.id"
      :no-close-on-backdrop="true"
      :title="title"
      @modal-closed="handleModalClosed"
  />
  <CreateNewAnswerModal
      :axios="axios"
      :id="selectedItem?.id"
      :no-close-on-backdrop="true"
      :title="title"
      @modal-closed="handleModalClosed"
  />
</template>

<script>
import {computed} from "vue";
import CreateNewQuestionsModal from "./CreateNewQuestionsModal.vue";
import * as StateStore from "@/utils/store"
import Swal from "sweetalert2"; // Importa SweetAlert2
import CreateNewAnswerModal from "./CreateNewAnswerModal.vue";
import * as bootstrap from 'bootstrap';

export default {
  name: "QuestionsList",
  props: {
    axios: {
      type: [Object, Function],
      required: true
    }
  },
  components: {
    CreateNewQuestionsModal,
    CreateNewAnswerModal
  },
  data() {
    return {
      searchQuery: '',
      selectedItem: null,
      itemsPerPage: 10,
      currentPage: 1,
      items: [],
      title: computed(() => this.selectedItem === null ? 'Crear Nueva ' : 'Editar '),

    }
  },
  computed: {
    filteredAnswers() {
      return this.items.filter(item => {
        return (
            item.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            item.level?.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            item.level?.game?.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            item.point.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      });
    },
    paginatedItems() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredAnswers.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredAnswers.length / this.itemsPerPage);
    }
  },
  methods: {
    filterResults() {
      // Este método se llama al enviar el formulario, pero no es necesario hacer nada aquí
    },
    handleModalClosed() {
      this.selectedItem = null;
      // console.log("selectedItem.value: ", selectedItem.value);

    },
    openModal(item) {
      this.selectedItem = item;
      console.log("selectedItem: ", this.selectedItem);

    },
    openModalRespuesta(event, question) {
    // Verifica si la pregunta ya tiene tres respuestas asignadas
    if (question.answers.length === 3) {
      // Prevenir la acción por defecto de abrir el modal
      event.preventDefault();
      event.stopPropagation();
      // Mostrar mensaje de advertencia
      Swal.fire({
        title: "Error",
        text: "La pregunta tiene sus tres respuestas asignadas.",
        icon: "warning",
        confirmButtonText: "Aceptar",
      });
      return; // Terminar la ejecución si la condición se cumple
    }else{
 // Si no se cumplen las condiciones, el modal se abrirá normalmente
 this.selectedItem = question; // Realiza cualquier acción adicional que necesites
  // Abrir el modal programáticamente
  const modal = new bootstrap.Modal(document.getElementById('CreateNewAnswerModal'));
    modal.show();
    }
   
  },
    
    async deleteQuestion(id) {
       // Usando SweetAlert2 para confirmar la eliminación
       const result = await Swal.fire({
        title: "¿Está seguro de eliminar este registro?",
        text: "No podrá revertir esta acción.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      });

      if (result.isConfirmed) {
        try {
          await StateStore.actions.actionsDeleteQuestions({
            axios: this.axios,
            id,
          });
          await this.getAllQuestions(); // Recarga los datos después de la eliminación.

          // Mostrar mensaje de éxito
          Swal.fire({
            title: "Eliminado",
            text: "La pregunta ha sido eliminado correctamente.",
            icon: "success",
            confirmButtonText: "Aceptar",
          });
        } catch (error) {
          console.error("Error al eliminar la pregunta:", error);
          Swal.fire({
            title: "Error",
            text: "Hubo un problema al eliminar la pregunta.",
            icon: "error",
            confirmButtonText: "Aceptar",
          });
        }
      }

}
,
    goToPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    goToPage(page) {
      this.currentPage = page;
    },
    goToNextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    async getAllQuestions() {
      await StateStore.actions.actionsGetAllQuestionsList({'axios': this.axios});
      this.items = StateStore.getters.getQuestionsList();
    }
  },
  created() {
    this.getAllQuestions();
  },
}
// export default defineComponent({
//   name: "QuestionsList",
//   props: {
//     axios: {
//       type: [Object, Function],
//       required: true
//     }
//   },
//   components: {
//     CreateNewQuestionsModal,
//   },
//   setup(props) {
//     const selectedItem = ref(null);
//     const title = computed(() => selectedItem.value === null ? 'Crear Nuevo ' : 'Editar ');
//     const questions = ref([] as any[]);
//
//     return {
//       loading: ref(false),
//       alert: ref(false),
//       questions,
//       selectedItem,
//       title,
//       handleModalClosed() {
//         selectedItem.value = null;
//         // console.log("selectedItem.value: ", selectedItem.value);
//
//       },
//       openModal(item) {
//         selectedItem.value = item;
//         console.log("selectedItem.value: ", selectedItem.value);
//
//       },
//       async deleteQuestion(id: number) {
//         if (confirm("¿Está seguro de que desea eliminar la Pregunta?")) {
//           console.log('El usuario confirmó la eliminación');
//           await StateStore.actions.actionsDeleteQuestions({'axios': props.axios, 'id': id});
//           location.reload();
//         } else {
//           console.log('El usuario canceló la eliminación');
//         }
//       },
//       async getAllQuestions() {
//         await StateStore.actions.actionsGetAllQuestionsList({'axios': props.axios});
//         questions.value = StateStore.getters.getQuestionsList();
//       }
//     };
//   },
//   created() {
//     this.getAllQuestions();
//   },
// });

</script>
<style>
.long-text {
  white-space: pre-wrap; /* Permite que el texto se divida en varias líneas */
  word-break: break-word; /* Rompe las palabras largas */
}
.cursor-pointer {
  cursor: pointer;
}
</style>