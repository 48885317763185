<template>
  <div class="only card mb-25 border-0 rounded-0 welcome-box">
    <div class="card-body pe-15 pe-sm-20 pe-md-0 pb-0 pt-15 pt-sm-20">
      <div class=" row align-items-center">
        <div class="col-lg-6 col-md-6">
          <div class="title position-relative">
            <h3 class="fw-semibold mb-8 text-white">
              Jugadores
            </h3>
            <span class="d-block  text-white fs-md-15 fs-lg-16">
              "Guardianes del Agua"
            </span>
          </div>
          <ul class="ps-0 mb-0 list-unstyled">
            <li
              class="d-inline-block text-uppercase text-white fw-medium fs-13  position-relative"
            >
              Jugadores
              <span class="d-block fw-black lh-1 text-white  mt-5 mt-md-10">
                {{ gamer.length }}
              </span>
            </li>
            <li
              class="d-inline-block text-uppercase fw-medium text-white fs-13  position-relative"
            >
              Ganadores
              <span class="d-block fw-black lh-1 text-white  mt-5 mt-md-10">
                {{ winner.length }}
              </span>
            </li>
          </ul>
        </div>
        <div class="col-lg-6 col-md-6 text-center mt-15 mt-md-0">
          <img
              src="../../../assets/images/favicon.png"
            alt="welcome-image"
              style="height: 190px; width: 190px"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as StateStore from "@/utils/store";

export default {
  name: "WhatHappening",
  props: {
    axios: {
      type: [Object, Function],
      required: true
    }
  },
  data() {
    return {
      winner: [],
      gamer: [],
    }
  },
  methods: {
    async getData() {
      await StateStore.actions.actionsGetAllUsersGamerList({'axios': this.axios});
      this.gamer = StateStore.getters.getUsersGamerList();
      await StateStore.actions.actionsGetAllUsersWinnerList({'axios': this.axios});
      this.winner = StateStore.getters.getUsersWinnerList();
    }
  },
  async mounted() {
    await this.getData()
  }
};
</script>
<style>
.only {
  background: linear-gradient(to right, #975067, #40091A) !important;
}
</style>