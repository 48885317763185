<template>
  <BreadCrumb PageTitle="Agregar Tópico"/>
  <AddTopic :axios="$axios"/>
</template>

<script lang="ts">
import {defineComponent} from "vue";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import AddTopic from "../../components/Topic/AddTopic/AddTopic.vue";

export default defineComponent({
  name: "AddTopicPage",
  components: {
    BreadCrumb,
    AddTopic,
  },
});
</script>