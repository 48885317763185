import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-ef771f2a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "file-upload text-center position-relative" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["onUpdate:modelValue"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "d-block text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.imageUrls, (image, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "image-preview-container"
      }, [
        _createElementVNode("img", {
          src: image.url,
          alt: "Ficheros",
          class: "preview-image"
        }, null, 8, _hoisted_2),
        _withDirectives(_createElementVNode("input", {
          "onUpdate:modelValue": ($event: any) => ((image.text) = $event),
          class: "image-text-input",
          placeholder: "Descripción de la imagen"
        }, null, 8, _hoisted_3), [
          [_vModelText, image.text]
        ]),
        _createElementVNode("button", {
          onClick: ($event: any) => (_ctx.removeImage(index)),
          class: "btn-remove"
        }, "Eliminar", 8, _hoisted_4)
      ]))
    }), 128)),
    _createElementVNode("span", _hoisted_5, [
      _createTextVNode(" Eliminar Ficheros "),
      _createElementVNode("span", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.uploadImages && _ctx.uploadImages(...args))),
        class: "text-black fw-medium position-relative"
      }, " Subir ")
    ]),
    _createElementVNode("input", {
      type: "file",
      class: "d-block shadow-none border-0 position-absolute start-0 end-0 top-0 bottom-0 z-1 opacity-0",
      ref: "fileInput",
      onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleFileChange && _ctx.handleFileChange(...args))),
      multiple: ""
    }, null, 544)
  ]))
}