<template>
  <div
      ref="mymodal"
      class="modal fade createNewModal"
      id="createEditCategoryModal"
      tabindex="-1"
      aria-hidden="true"
      @click.self="handleBackdropClick"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-15 p-md-40">
        <div class="modal-header ps-0 pe-0 pt-0 pb-15 pb-md-25">
          <h5 class="modal-title fw-bold text-black">{{ title }} Categoria de Elemento</h5>
        </div>
        <div class="modal-body ps-0 pe-0 pb-0 pt-15 pt-md-25">
          <form>
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <div class="form-group mb-15 mb-md-25">
                  <label class="text-black fw-semibold mb-10">
                    Nombre Categoria
                  </label>
                  <input
                      v-model="item.name"
                      type="text"
                      class="form-control shadow-none rounded-0 text-black"
                      placeholder="e.g. Alberca"
                  />
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <div class="form-group mb-15 mb-md-25">
                  <label class="text-black fw-semibold mb-10">
                    Sección
                  </label>
                  <select v-model="item.section" class="form-control shadow-none rounded-0 text-black">
                    <option
                        v-for="(categoryOption, index) in section"
                        :value="categoryOption.id"
                        :key="index"
                    >
                      {{ categoryOption.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div v-if="error !== '' || showError">
              <div class="row" v-if="error != null || showError">
                <span class="text-muted text-danger">{{ error }}</span>
              </div>
            </div>
            <div style="display: flex; justify-content: space-between;">
              <button
                  class="text-danger default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16"
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
              >
                Cancelar
              </button>
              <button
                  class="default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15"
                  type="submit"
                  @click.prevent="submitForm"
              >
                {{ title }} Categoria
              </button>
            </div>
          </form>
        </div>
        <button
            type="button"
            class="btn-close shadow-none"
            data-bs-dismiss="modal"
            aria-label="Close"
        ></button>
      </div>
    </div>
  </div>
</template>

<script>
import * as StateStore from "@/utils/store";
import {stateStore} from "@/utils/store";

export default {
  name: "CreateEditCategoryModal",
  props: {
    axios: {
      type: [Object, Function],
      required: true
    },
    id: {
      type: Number,
      required: false,
    },
    noCloseOnBackdrop: Boolean,
    edit: Boolean,
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      error: '',
      showError: false,
      maxLen: 10,
      minLen: 8,
      showPassword: false,
      password: '',
      password2: '',
      item: {
        name: '',
        section: null,
      },
      section: [],
    };
  },
  watch: {
    async id(newVal) {
      console.log('newVal: ', newVal)
      if (newVal > 0) {
        await StateStore.actions.actionsGetCategoryElementos({'axios': this.axios, 'id': newVal});
        this.item = await StateStore.getters.getCategoryElementosRetrieve();
        console.log("itemRetrieve: ", this.item);
      } else if (newVal === undefined) {
        this.item = [];
      }
    }
  },
  mounted() {
    this.$refs.mymodal.addEventListener('hidden.bs.modal', this.handleModalHidden);
  },
  beforeUnmount() {
    this.$refs.mymodal.removeEventListener('hidden.bs.modal', this.handleModalHidden);
  },
  methods: {
    validatePassword() {
      if (this.password !== this.password2) {
        this.showError = true;
        this.error = 'Las contraseñas no coinciden';
        this.alert = true;
        console.log("password don't match");
      } else {
        this.error = '';
        this.alert = false;
        this.showError = false;
        console.log("password match");
      }
    },
    checkMinLength(field, minLen) {
      if (field === 'phone' && this.item[field] && (this.item[field].toString()).length < minLen) {
        this.error = `El campo debe tener más de ${minLen ? minLen : this.minLen} caracteres`;
        this.showError = true;
        setTimeout(() => {
          this.showError = false;
          this.error = '';
        }, 5000);
      } else if (this.item[field] && this.item[field].length < (minLen ? minLen : this.minLen)) {
        this.error = `El campo debe tener más de ${minLen ? minLen : this.minLen} caracteres`;
        this.showError = true;
      }
    },
    checkLength(field, maxLen) {
      if (field === 'phone') {
        if (isNaN(this.item[field])) {
          this.showError = true;
          this.error = `Número de Teléfono invalido, rectifiquelo`;
        }
        if (this.item && this.item[field] && (this.item[field].toString()).length > maxLen) {
          this.error = `El campo no puede tener más de ${maxLen ? maxLen : this.maxLen} caracteres`;
          this.showError = true;
        }
        setTimeout(() => {
          this.showError = false;
          this.error = '';
        }, 5000);
      } else if (this.item && this.item[field] && this.item[field].length >= (maxLen ? maxLen : this.maxLen)) {
        this.showError = true;
        this.error = `El campo no puede tener más de ${maxLen ? maxLen : this.maxLen} caracteres`;
        console.log("error: ", field);
        console.log("error: ", this.error);
        console.log("error: ", this.showError);
      } else {
        this.showError = false;
        this.error = '';
      }
    },
    handleModalHidden() {
      this.$emit('modal-closed');
    },
    handleBackdropClick(event) {
      if (this.noCloseOnBackdrop) {
        event.stopPropagation();
      }
    },
    async getAllSection() {
      await StateStore.actions.actionsGetAllSectionList({'axios': this.axios});
      this.section = StateStore.getters.getSectionList();
    },
    async submitForm() {
      if (this.id === undefined) {
        console.log('this.id: ', this.id);
        await StateStore.actions.actionsCreateCategoryElementos({
          'axios': this.$axios,
          'name': this.item.name,
          'section': this.item.section,
        })
            .then((response) => {
              if (response || stateStore.ok) {
                location.reload();
              } else {
                // Maneja el error
                console.error('Error al crear el usuario:', response);
              }
            }).catch((error) => {
              // Maneja cualquier error que pueda ocurrir durante la ejecución
              console.error('Error al crear el usuario:', error);
            });
      } else {
        await StateStore.actions.actionsEditCategoyElementos({
          'axios': this.$axios,
          'id': this.item.id,
          'name': this.item.name,
          'section': this.item.section,
        })
            .then((response) => {
              if (response || stateStore.ok) {
                location.reload();
              } else {
                // Maneja el error
                console.error('Error al editar el usuario:', response);
              }
            }).catch((error) => {
              // Maneja cualquier error que pueda ocurrir durante la ejecución
              console.error('Error al editar el usuario:', error);
            });
      }
    },
  },
  created() {
    console.log("props item: ", this.item);
    this.getAllSection();
  },
};
</script>
<style scoped>
.form-group {
  position: relative;
}

.form-control {
  padding-right: 40px; /* Espacio para el botón */
}

.btn {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin-right: 10px; /* Ajusta esto según sea necesario */
}

.d-block {
  color: #FFFFFF !important;
}
</style>