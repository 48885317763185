<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
        class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <a
            class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0 text-decoration-none"
            href="#"
            data-bs-toggle="modal"
            data-bs-target="#createNewGameModal"
            @click.prevent="handleModalClosed"
        >
          Agregar Juego
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
        </a>
        <!--        <button-->
        <!--            class="default-outline-btn position-relative transition fw-medium text-black pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-transparent fs-md-15 fs-lg-16 d-inline-block mb-10 mb-lg-0"-->
        <!--            type="button"-->
        <!--        >-->
        <!--          Export-->
        <!--          <i class="flaticon-file-1 position-relative ms-5 top-2 fs-15"></i>-->
        <!--        </button>-->
      </div>
      <div class="d-flex align-items-center">
        <form class="search-box position-relative me-15">
          <input
              type="text"
              class="form-control shadow-none text-black rounded-0 border-0"
              placeholder="Buscar Juego"
          />
          <button
              type="submit"
              class="bg-transparent text-primary transition p-0 border-0"
          >
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
        <button
            class="dot-btn lh-1 position-relative top-3 bg-transparent border-0 shadow-none p-0 transition d-inline-block"
            type="button"
        >
          <i class="flaticon-dots"></i>
        </button>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
          <tr>
            <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
            >
              NOMBRE JUEGO
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
            >
              ACCIONES
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(game, index) in games" :key="index">
            <th
                class="shadow-none lh-1 fw-medium text-black-emphasis title ps-0"
            >
              <div class="d-flex align-items-center">

                <div class="d-flex align-items-center ms-5 fs-md-15 fs-lg-16">
                  {{ game.name }}
                </div>
              </div>
            </th>
            <td class="action-link">
              <!--              <router-link :to="`/profile/${game.id}`">-->
              <!--                <i class="ph ph-eye me-2 action-link" style="font-size: 24px;"/>-->
              <!--              </router-link>-->
              <i
                  class="ph ph-pencil action-link cursor-pointer"
                  style="font-size: 24px;"
                  data-bs-toggle="modal"
                  data-bs-target="#createNewGameModal"
                  @click="openModal(game)"
              />
              <i
                  class="ph ph-trash ms-2 action-link cursor-pointer"
                  style="font-size: 24px;"
                  @click="deleteGame(game.id)"
              />
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <CreateNewGameModal
      :axios="axios"
      :id="selectedItem?.id"
      :no-close-on-backdrop="true"
      :title="title"
      @modal-closed="handleModalClosed"
  />
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import CreateNewGameModal from "./CreateNewGameModal.vue";
import * as StateStore from "@/utils/store";
import Swal from "sweetalert2"; // Importa SweetAlert2

export default defineComponent({
  name: "GameList",
  props: {
    axios: {
      type: [Object, Function],
      required: true
    }
  },
  components: {
    CreateNewGameModal,
  },
  setup(props) {
    const selectedItem = ref(null);
    const title = computed(() => (selectedItem.value === null ? 'Crear Nuevo ' : 'Editar '));
    const games = ref([] as any[]);

    const getAllGame = async () => {
      await StateStore.actions.actionsGetAllGameList({ axios: props.axios });
      games.value = StateStore.getters.getGameList();
    };

    const handleModalClosed = () => {
      selectedItem.value = null;
    };

    const openModal = (item: any) => {
      selectedItem.value = item;
      console.log("selectedItem.value: ", selectedItem.value);
    };

    const deleteGame = async (id: number) => {
      const result = await Swal.fire({
        title: "¿Está seguro de eliminar este registro?",
        text: "No podrá revertir esta acción.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      });

      if (result.isConfirmed) {
        try {
          await StateStore.actions.actionsDeleteGame({
            axios: props.axios,
            id,
          });
          await getAllGame(); // Recarga los datos después de la eliminación.

          // Mostrar mensaje de éxito
          Swal.fire({
            title: "Eliminado",
            text: "El juego ha sido eliminado correctamente.",
            icon: "success",
            confirmButtonText: "Aceptar",
          });
        } catch (error) {
          console.error("Error al eliminar el juego:", error);
          Swal.fire({
            title: "Error",
            text: "Hubo un problema al eliminar el juego.",
            icon: "error",
            confirmButtonText: "Aceptar",
          });
        }
      }
    };

    // Cargar juegos al crear el componente
    getAllGame();

    return {
      loading: ref(false),
      alert: ref(false),
      games,
      selectedItem,
      title,
      handleModalClosed,
      openModal,
      deleteGame,
      getAllGame,
    };
  },
});
</script>


<style>

.cursor-pointer {
  cursor: pointer;
}
</style>