<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
        class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <a
            class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block me-10 mb-10 mb-lg-0 text-decoration-none"
            href="#"
            data-bs-toggle="modal"
            data-bs-target="#CreateNewEducSectionModal"
            @click.prevent="handleModalClosed"
        >
          Agregar Sección Educativa
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
        </a>
        <!--        <button-->
        <!--            class="default-outline-btn position-relative transition fw-medium text-black pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-transparent fs-md-15 fs-lg-16 d-inline-block mb-10 mb-lg-0"-->
        <!--            type="button"-->
        <!--        >-->
        <!--          Export-->
        <!--          <i class="flaticon-file-1 position-relative ms-5 top-2 fs-15"></i>-->
        <!--        </button>-->
      </div>
      <div class="d-flex align-items-center">
        <form class="search-box position-relative me-15" @submit.prevent="filterResults">
          <input
              type="text"
              v-model="searchQuery"
              class="form-control shadow-none text-black rounded-0 border-0"
              placeholder="Buscar"
          />
          <button
              type="submit"
              class="bg-transparent text-primary transition p-0 border-0"
          >
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
          <tr>
            <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0">Respuesta
            </th>
            <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0">Tópicos</th>
            <!--            <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0">Acciones</th>-->
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in paginatedItems" :key="index">
            <th class="shadow-none lh-1 fw-medium text-black-emphasis title ps-0">
              <div class="d-flex align-items-center">
                <div class="d-flex align-items-center ms-5 fs-md-15 fs-lg-16 long-text">{{ item.name }}</div>
              </div>
            </th>
            <th class="shadow-none lh-1 fw-medium text-black-emphasis title ps-0">
              <div class="d-flex align-items-center">
                <div class="d-flex align-items-center ms-5 fs-md-15 fs-lg-16">{{ returnCount(item.topics) }}</div>
              </div>
            </th>

            <td class="action-link">
              <i class="ph ph-pencil action-link cursor-pointer" style="font-size: 24px;" data-bs-toggle="modal"
                 data-bs-target="#CreateNewEducSectionModal" @click="openModal(item)"/>
              <i class="ph ph-trash ms-2 action-link cursor-pointer" style="font-size: 24px;" @click="deleteSeccion(item.id)"/>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="pagination-area d-md-flex mb-25 justify-content-between align-items-center">
        <p class="ms-20 mb-0 text-paragraph">
          Mostrando <span class="fw-bold">{{ paginatedItems.length }}</span> de
          <span class="fw-bold">{{ items.length }}</span> Resultados
        </p>
        <nav class="mt-15 mt-md-0">
          <ul class="pagination mb-0">
            <li class="page-item">
              <a class="page-link" @click.prevent="goToPreviousPage" aria-label="Previous">
                <i class="flaticon-chevron-1"></i>
              </a>
            </li>
            <li class="page-item" v-for="page in totalPages" :key="page">
              <a class="page-link" @click.prevent="goToPage(page)">{{ page }}</a>
            </li>
            <li class="page-item">
              <a class="page-link" @click.prevent="goToNextPage" aria-label="Next">
                <i class="flaticon-chevron"></i>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>

  </div>
  <CreateNewEducSectionModal
      :axios="axios"
      :id="selectedItem?.id"
      :no-close-on-backdrop="true"
      :title="title"
      @modal-closed="handleModalClosed"
  />

</template>

<script>
import * as StateStore from "@/utils/store"
import CreateNewEducSectionModal from "./CreateNewEducSectionModal.vue";
import {computed} from "vue";
import Swal from "sweetalert2"; // Importa SweetAlert2


export default {
  name: "EducationalSectionsList",
  props: {
    axios: {
      type: [Object, Function],
      required: true
    }
  },
  components: {CreateNewEducSectionModal},
  data() {
    return {
      searchQuery: '',
      selectedItem: null,
      itemsPerPage: 10,
      currentPage: 1,
      items: [],
      title: computed(() => this.selectedItem === null ? 'Crear Nueva ' : 'Editar '),

    }
  },
  computed: {
    filteredAnswers() {
      return this.items.filter(item => {
        return (
            item.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            item.topics?.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            item.topics?.text.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      });
    },
    paginatedItems() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredAnswers.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredAnswers.length / this.itemsPerPage);
    }

  },
  methods: {
    returnCount(item) {
      return item.length
    },
    filterResults() {
      // Este método se llama al enviar el formulario, pero no es necesario hacer nada aquí
    },
    handleModalClosed() {
      this.selectedItem = null;
      // console.log("selectedItem.value: ", selectedItem.value);

    },
    openModal(item) {
      this.selectedItem = item;
      console.log("selectedItem: ", this.selectedItem);

    },
    async deleteSeccion(id) {
      // Usando SweetAlert2 para confirmar la eliminación
      const result = await Swal.fire({
        title: "¿Está seguro de eliminar este registro?",
        text: "No podrá revertir esta acción.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      });

      if (result.isConfirmed) {
        try {
          await StateStore.actions.actionsDeleteEducSections({
            axios: this.axios,
            id,
          });
          await this.getData(); // Recarga los datos después de la eliminación.

          // Mostrar mensaje de éxito
          Swal.fire({
            title: "Eliminado",
            text: "La sección educativa ha sido eliminada correctamente.",
            icon: "success",
            confirmButtonText: "Aceptar",
          });
        } catch (error) {
          console.error("Error al eliminar la sección educativa:", error);
          Swal.fire({
            title: "Error",
            text: "Hubo un problema al eliminar la sección educativa.",
            icon: "error",
            confirmButtonText: "Aceptar",
          });
        }
      }
      
    },
    goToPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    goToPage(page) {
      this.currentPage = page;
    },
    goToNextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    async getData() {
      await StateStore.actions.actionsGetAllEducSectionsList({'axios': this.axios});
      this.items = StateStore.getters.getEducationalSectionsList();
    }
  },
  async mounted() {
    await this.getData();
  },
};

</script>
<style>
.long-text {
  white-space: pre-wrap; /* Permite que el texto se divida en varias líneas */
  word-break: break-word; /* Rompe las palabras largas */
}
.cursor-pointer {
  cursor: pointer;
}
</style>