<template>
  <div
    class="card mb-25 border-0 rounded-0 bg-white letter-spacing search-result-card"
  >
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
      <div class="mb-15 d-flex align-items-center justify-content-between">
        <span class="d-block text-paragraph fs-md-15 fs-lg-16 fw-medium">
          <span class="fw-medium text-black">99</span>
          results found for
          <span class="fw-medium text-black">“CESPT”</span>
        </span>
        <span class="d-block text-paragraph">Showing 1-10 of 76 results</span>
      </div>
      <ul class="list ps-0 mb-0 list-unstyled">
        <li>
          <span class="d-block text-black fs-md-15 fs-lg-16 fw-medium mb-8">
            <span class="fw-medium text-primary">CESPT</span> here’s what
            happening with your store today
          </span>
          <span class="d-block text-paragraph fs-md-15 fs-lg-16">
            Lorem ipsum dolor amet consetetur get up and running with a
            world-class sadipscing elitr, sed diam nonumy eirmod...
          </span>
        </li>
        <li>
          <span class="d-block text-black fs-md-15 fs-lg-16 fw-medium mb-8">
            <span class="fw-medium text-primary">CESPT</span> here’s what
            happening with your store today
          </span>
          <span class="d-block text-paragraph fs-md-15 fs-lg-16">
            Lorem ipsum dolor amet consetetur get up and running with a
            world-class sadipscing elitr, sed diam nonumy eirmod...
          </span>
        </li>
        <li>
          <span class="d-block text-black fs-md-15 fs-lg-16 fw-medium mb-8">
            <span class="fw-medium text-primary">CESPT</span> here’s what
            happening with your store today
          </span>
          <span class="d-block text-paragraph fs-md-15 fs-lg-16">
            Lorem ipsum dolor amet consetetur get up and running with a
            world-class sadipscing elitr, sed diam nonumy eirmod...
          </span>
        </li>
        <li>
          <span class="d-block text-black fs-md-15 fs-lg-16 fw-medium mb-8">
            <span class="fw-medium text-primary">CESPT</span> here’s what
            happening with your store today
          </span>
          <span class="d-block text-paragraph fs-md-15 fs-lg-16">
            Lorem ipsum dolor amet consetetur get up and running with a
            world-class sadipscing elitr, sed diam nonumy eirmod...
          </span>
        </li>
        <li>
          <span class="d-block text-black fs-md-15 fs-lg-16 fw-medium mb-8">
            <span class="fw-medium text-primary">CESPT</span> here’s what
            happening with your store today
          </span>
          <span class="d-block text-paragraph fs-md-15 fs-lg-16">
            Lorem ipsum dolor amet consetetur get up and running with a
            world-class sadipscing elitr, sed diam nonumy eirmod...
          </span>
        </li>
        <li>
          <span class="d-block text-black fs-md-15 fs-lg-16 fw-medium mb-8">
            <span class="fw-medium text-primary">CESPT</span> here’s what
            happening with your store today
          </span>
          <span class="d-block text-paragraph fs-md-15 fs-lg-16">
            Lorem ipsum dolor amet consetetur get up and running with a
            world-class sadipscing elitr, sed diam nonumy eirmod...
          </span>
        </li>
        <li>
          <span class="d-block text-black fs-md-15 fs-lg-16 fw-medium mb-8">
            <span class="fw-medium text-primary">CESPT</span> here’s what
            happening with your store today
          </span>
          <span class="d-block text-paragraph fs-md-15 fs-lg-16">
            Lorem ipsum dolor amet consetetur get up and running with a
            world-class sadipscing elitr, sed diam nonumy eirmod...
          </span>
        </li>
        <li>
          <span class="d-block text-black fs-md-15 fs-lg-16 fw-medium mb-8">
            <span class="fw-medium text-primary">CESPT</span> here’s what
            happening with your store today
          </span>
          <span class="d-block text-paragraph fs-md-15 fs-lg-16">
            Lorem ipsum dolor amet consetetur get up and running with a
            world-class sadipscing elitr, sed diam nonumy eirmod...
          </span>
        </li>
      </ul>
      <nav class="mt-15 mt-sm-20 mt-md-25 mt-lg-30">
        <ul class="pagination mb-0">
          <li class="page-item">
            <a class="page-link" href="#" aria-label="Previous">
              <i class="flaticon-chevron-1"></i>
            </a>
          </li>
          <li class="page-item"><a class="page-link active" href="#">1</a></li>
          <li class="page-item"><a class="page-link" href="#">2</a></li>
          <li class="page-item"><a class="page-link" href="#">3</a></li>
          <li class="page-item">
            <a class="page-link" href="#" aria-label="Next">
              <i class="flaticon-chevron"></i>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchResult",
};
</script>