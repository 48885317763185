<template>
  <div class="row">
    <div class="col-xxxl-9">
      <div
        class="card mb-25 border-0 rounded-0 bg-white letter-spacing ticket-preview-box"
      >
        <div
          class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between pt-15 pb-15 ps-15 pe-15 ps-sm-20 pe-sm-20 ps-md-25 pe-md-25 ps-lg-30 pe-lg-30"
        >
          <span class="d-block text-primary fw-medium fs-md-16 fs-lg-18">
            CESPT Dashboard Design Problem #Ticket 1436
          </span>
          <div class="d-flex align-items-center">
            <div class="buttons-list">
              <button
                class="bg-transparent fw-medium text-black rounded-1 position-relative transition"
                type="button"
              >
                Ban Visitor <i class="flaticon-ban"></i>
              </button>
              <button
                class="bg-transparent fw-medium text-black rounded-1 position-relative transition"
                type="button"
              >
                Merge <i class="flaticon-merge"></i>
              </button>
              <button
                class="bg-transparent fw-medium text-black rounded-1 position-relative transition"
                type="button"
              >
                Close <i class="flaticon-cancel"></i>
              </button>
              <button
                class="bg-transparent fw-medium text-black rounded-1 position-relative transition"
                type="button"
              >
                Delete <i class="flaticon-delete"></i>
              </button>
            </div>
            <button
              class="dot-btn lh-1 position-relative top-3 bg-transparent border-0 shadow-none p-0 transition d-inline-block ms-10"
              type="button"
            >
              <i class="flaticon-dots"></i>
            </button>
          </div>
        </div>
        <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30">
          <div class="ticket-preview-list">
            <div class="item position-relative">
              <div class="d-md-flex">
                <div class="image">
                  <img
                    src="../../../assets/images/user/user4.jpg"
                    width="55"
                    height="55"
                    class="rounded-circle"
                    alt="user"
                  />
                </div>
                <div class="ms-md-15 ms-lg-20 mt-12 mt-md-0">
                  <div
                    class="mb-15 mb-sm-20 mb-md-25 d-sm-flex align-items-center justify-content-between"
                  >
                    <div>
                      <span
                        class="d-block mb-6 mb-md-10 text-black fw-medium fs-md-16 fs-lg-18 lh-1"
                        >Brewen Jesica</span
                      >
                      <span class="d-block text-muted"
                        >jane324506@gmail.com</span
                      >
                    </div>
                    <span class="d-block mt-5 mt-sm-0 text-secondary"
                      >27th Mar, Monday - 10:10 am</span
                    >
                  </div>
                  <span
                    class="mb-10 d-block text-black fw-medium fs-md-15 fs-lg-16"
                    >Hello!</span
                  >
                  <p class="text-paragraph fs-md-16 fs-lg-18 lh-base">
                    Last week I buy CESPT dashboard but I couldn’t find the
                    figma file of the design. I badly need the design file. I
                    also tried to install the react file but I couldn’t install
                    it.
                  </p>
                  <p class="text-paragraph fs-md-16 fs-lg-18 lh-base">
                    Do you guys help me to install the file? Here I enclosed the
                    screenshots and zip file for your understand.
                  </p>
                  <span class="d-block text-paragraph fs-md-16 fs-lg-18"
                    >Thanks,</span
                  >
                  <span class="d-block text-paragraph fs-md-16 fs-lg-18"
                    >Jesica</span
                  >
                  <div
                    class="mt-15 d-sm-flex align-items-center justify-content-between"
                  >
                    <div class="buttons-list">
                      <a
                        href="javascript:void(0);"
                        class="d-inline-block text-decoration-none text-black position-relative transition"
                        data-bs-toggle="tooltip"
                        data-bs-title="Click to download"
                      >
                        <i class="flaticon-gallery-1"></i> Dashboard.jpg
                      </a>
                      <a
                        href="javascript:void(0);"
                        class="d-inline-block text-decoration-none text-black position-relative transition"
                        data-bs-toggle="tooltip"
                        data-bs-title="Click to download"
                      >
                        <i class="flaticon-gallery-1"></i> Structure.zip
                      </a>
                    </div>
                    <button
                      class="default-btn position-relative transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 fs-md-15 fs-lg-16 d-inline-block mt-15 mt-sm-0"
                      type="button"
                    >
                      Reply
                    </button>
                  </div>
                </div>
              </div>
              <span
                class="more-conversation text-muted fw-semibold d-inline-block bg-white"
                >2+ More Conversation</span
              >
            </div>
            <div class="item position-relative">
              <div class="d-md-flex">
                <div class="image">
                  <img
                    src="../../../assets/images/user/user1.jpg"
                    width="55"
                    height="55"
                    class="rounded-circle"
                    alt="user"
                  />
                </div>
                <div class="ms-md-15 ms-lg-20 mt-12 mt-md-0">
                  <div
                    class="mb-15 mb-sm-20 mb-md-25 d-sm-flex align-items-center justify-content-between"
                  >
                    <div>
                      <span
                        class="d-block mb-6 mb-md-10 text-black fw-medium fs-md-16 fs-lg-18 lh-1"
                        >Victor James</span
                      >
                      <span class="d-block text-muted"
                        >james324506@gmail.com</span
                      >
                    </div>
                    <span class="d-block mt-5 mt-sm-0 text-secondary"
                      >28th Mar, Tuesday - 10:10 am</span
                    >
                  </div>
                  <span
                    class="mb-10 d-block text-black fw-medium fs-md-15 fs-lg-16"
                    >Hello Jesica!</span
                  >
                  <p class="text-paragraph fs-md-16 fs-lg-18 lh-base">
                    We are really apologise for the inconvenience.
                  </p>
                  <p class="text-paragraph fs-md-16 fs-lg-18 lh-base">
                    In support we mention that we didn’t provide the design
                    figma file with the code file. If you need it we can provide
                    this here for your help.
                  </p>
                  <span class="d-block text-paragraph fs-md-16 fs-lg-18"
                    >Thanks,</span
                  >
                  <span class="d-block text-paragraph fs-md-16 fs-lg-18"
                    >James</span
                  >
                  <div
                    class="mt-15 d-sm-flex align-items-center justify-content-between"
                  >
                    <div class="buttons-list">
                      <a
                        href="javascript:void(0);"
                        class="d-inline-block text-decoration-none text-black position-relative transition"
                        data-bs-toggle="tooltip"
                        data-bs-title="Click to download"
                      >
                        <i class="flaticon-gallery-1"></i> Design.zip
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="item position-relative">
              <div class="d-md-flex">
                <div class="image">
                  <img
                    src="../../../assets/images/user/user4.jpg"
                    width="55"
                    height="55"
                    class="rounded-circle"
                    alt="user"
                  />
                </div>
                <div class="ms-md-15 ms-lg-20 mt-12 mt-md-0">
                  <div
                    class="mb-15 mb-sm-20 mb-md-25 d-sm-flex align-items-center justify-content-between"
                  >
                    <div>
                      <span
                        class="d-block mb-6 mb-md-10 text-black fw-medium fs-md-16 fs-lg-18 lh-1"
                        >Brewen Jesica</span
                      >
                      <span class="d-block text-muted"
                        >jane324506@gmail.com</span
                      >
                    </div>
                    <span class="d-block mt-5 mt-sm-0 text-secondary"
                      >27th Mar, Monday - 10:10 am</span
                    >
                  </div>
                  <span
                    class="mb-10 d-block text-black fw-medium fs-md-15 fs-lg-16"
                    >Hello!</span
                  >
                  <p class="text-paragraph fs-md-16 fs-lg-18 lh-base">
                    Last week I buy CESPT dashboard but I couldn’t find the
                    figma file of the design. I badly need the design file. I
                    also tried to install the react file but I couldn’t install
                    it.
                  </p>
                  <p class="text-paragraph fs-md-16 fs-lg-18 lh-base">
                    Do you guys help me to install the file? Here I enclosed the
                    screenshots and zip file for your understand.
                  </p>
                  <span class="d-block text-paragraph fs-md-16 fs-lg-18"
                    >Thanks,</span
                  >
                  <span class="d-block text-paragraph fs-md-16 fs-lg-18"
                    >Jesica</span
                  >
                  <div
                    class="mt-15 d-sm-flex align-items-center justify-content-between"
                  >
                    <div class="buttons-list">
                      <a
                        href="javascript:void(0);"
                        class="d-inline-block text-decoration-none text-black position-relative transition"
                        data-bs-toggle="tooltip"
                        data-bs-title="Click to download"
                      >
                        <i class="flaticon-gallery-1"></i> Dashboard.jpg
                      </a>
                      <a
                        href="javascript:void(0);"
                        class="d-inline-block text-decoration-none text-black position-relative transition"
                        data-bs-toggle="tooltip"
                        data-bs-title="Click to download"
                      >
                        <i class="flaticon-gallery-1"></i> Structure.zip
                      </a>
                    </div>
                    <button
                      class="default-btn position-relative transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 fs-md-15 fs-lg-16 d-inline-block mt-15 mt-sm-0"
                      type="button"
                    >
                      Reply
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xxxl-3">
      <div
        class="card mb-25 border-0 rounded-0 bg-white letter-spacing contact-information-card"
      >
        <div
          class="card-head d-flex justify-content-between align-items-center"
        >
          <h5 class="card-title lh-1 fs-md-18 fw-bold mb-0">
            Contact Information
          </h5>
          <div class="dropdown">
            <button
              class="dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="flaticon-dots"></i>
            </button>
            <ul class="dropdown-menu">
              <li>
                <a
                  class="dropdown-item d-flex align-items-center"
                  href="javascript:void(0);"
                  ><i
                    class="flaticon-view lh-1 me-8 position-relative top-1"
                  ></i>
                  View</a
                >
              </li>
              <li>
                <a
                  class="dropdown-item d-flex align-items-center"
                  href="javascript:void(0);"
                  ><i
                    class="flaticon-pen lh-1 me-8 position-relative top-1"
                  ></i>
                  Edit</a
                >
              </li>
              <li>
                <a
                  class="dropdown-item d-flex align-items-center"
                  href="javascript:void(0);"
                  ><i
                    class="flaticon-delete lh-1 me-8 position-relative top-1"
                  ></i>
                  Delete</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="card-body">
          <img
            src="../../../assets/images/user/user4.jpg"
            class="rounded-circle mb-20"
            width="95"
            height="95"
            alt="user"
          />
          <h5 class="fs-15 fs-md-18 fw-bold text-black mb-0">Brewen Jesica</h5>
          <ul class="info-list ps-0 mb-0 list-unstyled">
            <li class="text-paragraph fs-md-15 fs-lg-16 position-relative">
              <span class="fw-semibold text-muted fs-12 fs-md-13 fs-lg-14"
                >PHONE:</span
              >
              +1 234 4567 5678
            </li>
            <li class="text-paragraph fs-md-15 fs-lg-16 position-relative">
              <span class="fw-semibold text-muted fs-12 fs-md-13 fs-lg-14"
                >EMAIL:</span
              >
              jane324506@gmail.com
            </li>
            <li class="text-paragraph fs-md-15 fs-lg-16 position-relative">
              <span class="fw-semibold text-muted fs-12 fs-md-13 fs-lg-14"
                >ADDRESS:</span
              >
              795 Folsom Ave, Suite 600 San Francisco, CA 94107 United States
            </li>
          </ul>
        </div>
      </div>
      <div
        class="card mb-25 border-0 rounded-0 bg-white letter-spacing contact-information-card"
      >
        <div
          class="card-head d-flex justify-content-between align-items-center"
        >
          <h5 class="card-title lh-1 fs-md-18 fw-bold mb-0">Properties</h5>
          <div class="dropdown">
            <button
              class="dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="flaticon-dots"></i>
            </button>
            <ul class="dropdown-menu">
              <li>
                <a
                  class="dropdown-item d-flex align-items-center"
                  href="javascript:void(0);"
                  ><i
                    class="flaticon-view lh-1 me-8 position-relative top-1"
                  ></i>
                  View</a
                >
              </li>
              <li>
                <a
                  class="dropdown-item d-flex align-items-center"
                  href="javascript:void(0);"
                  ><i
                    class="flaticon-pen lh-1 me-8 position-relative top-1"
                  ></i>
                  Edit</a
                >
              </li>
              <li>
                <a
                  class="dropdown-item d-flex align-items-center"
                  href="javascript:void(0);"
                  ><i
                    class="flaticon-delete lh-1 me-8 position-relative top-1"
                  ></i>
                  Delete</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="card-body">
          <form>
            <div class="form-group mb-15 mb-md-20">
              <label class="d-block text-black fw-semibold mb-10"
                >Priority</label
              >
              <select class="form-select shadow-none fw-semibold rounded-0">
                <option selected>Urgent</option>
                <option value="1">2</option>
                <option value="2">3</option>
                <option value="3">4</option>
              </select>
            </div>
            <div class="form-group mb-15 mb-md-20">
              <label class="d-block text-black fw-semibold mb-10">Source</label>
              <select class="form-select shadow-none fw-semibold rounded-0">
                <option selected>Instagram</option>
                <option value="1">2</option>
                <option value="2">3</option>
                <option value="3">4</option>
              </select>
            </div>
            <div class="form-group mb-15 mb-md-20">
              <label class="d-block text-black fw-semibold mb-10">Status</label>
              <select class="form-select shadow-none fw-semibold rounded-0">
                <option selected>Recent</option>
                <option value="1">2</option>
                <option value="2">3</option>
                <option value="3">4</option>
              </select>
            </div>
            <div class="form-group mb-15 mb-md-20">
              <label class="d-block text-black fw-semibold mb-10">Group</label>
              <select class="form-select shadow-none fw-semibold rounded-0">
                <option selected>Billing</option>
                <option value="1">2</option>
                <option value="2">3</option>
                <option value="3">4</option>
              </select>
            </div>
            <div class="form-group mb-15 mb-md-20">
              <label class="d-block text-black fw-semibold mb-10">Agent</label>
              <select class="form-select shadow-none fw-semibold rounded-0">
                <option selected>Jonathon Ronan</option>
                <option value="1">2</option>
                <option value="2">3</option>
                <option value="3">4</option>
              </select>
            </div>
            <button
              class="default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-12 pb-md-12 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 d-block w-100"
              type="submit"
            >
              Save Properties
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TicketPreview",
};
</script>