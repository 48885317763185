<template>
  <BreadCrumb PageTitle="Ganadores"/>
  <UsersWinnerList :axios="$axios"/>
</template>

<script lang="ts">
import {defineComponent} from "vue";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import UsersWinnerList from "../../components/Users/UsersList/UsersWinnerList.vue";

export default defineComponent({
  name: "UsersWinnerListPage",
  components: {
    BreadCrumb,
    UsersWinnerList,
  }
});
</script>