import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "app",
  class: "gradient-background"
}
const _hoisted_2 = { class: "main-content d-flex flex-column transition overflow-hidden" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MainHeader = _resolveComponent("MainHeader")!
  const _component_MainSidebar = _resolveComponent("MainSidebar")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_MainFooter = _resolveComponent("MainFooter")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.mostrarDashboard)
      ? (_openBlock(), _createBlock(_component_MainHeader, {
          key: 0,
          axios: _ctx.$axios
        }, null, 8, ["axios"]))
      : _createCommentVNode("", true),
    (_ctx.mostrarDashboard)
      ? (_openBlock(), _createBlock(_component_MainSidebar, {
          key: 1,
          axios: _ctx.$axios
        }, null, 8, ["axios"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_view),
      (_ctx.mostrarDashboard)
        ? (_openBlock(), _createBlock(_component_MainFooter, { key: 0 }))
        : _createCommentVNode("", true)
    ])
  ]))
}