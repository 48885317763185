<template>
  <div
    ref="mymodal"
    class="modal fade createNewModal"
    id="createNewTopicModal"
    tabindex="-1"
    aria-hidden="true"
    @click.self="handleBackdropClick"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content p-3">
        <div class="modal-header">
          <h5 class="modal-title fw-bold">{{ title }} Tópico</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <form @submit.prevent="submitForm">
            <div class="row">
              <div class="col-md-6">
                <div :class="['form-group mb-3', { 'has-error': !item.name && showError }]">
                  <label class="form-label fw-semibold">Nombre</label>
                  <input
                    v-model="item.name"
                    type="text"
                    class="form-control"
                    placeholder="e.g. Nombre Tópico"
                  />
                  <span v-if="!item.name && showError" class="text-danger">Este campo es requerido.</span>
                </div>
              </div>
              <div class="col-md-6">
                <div :class="['form-group mb-3', { 'has-error': !item.educationalSections && showError }]">
                  <label class="form-label fw-semibold">Sección Educativa</label>
                  <select v-model="item.educationalSections" class="form-control">
                    <option v-for="(option, index) in educationalSections" :value="option.id" :key="index">
                      {{ option.name }}
                    </option>
                  </select>
                  <span v-if="!item.educationalSections && showError" class="text-danger">Seleccione una opción.</span>
                </div>
              </div>
              <div class="col-md-12">
                <div :class="['form-group mb-3', { 'has-error': !item.urlVideo && showError }]">
                  <label class="form-label fw-semibold">URL Video</label>
                  <input
                    v-model="item.urlVideo"
                    type="text"
                    class="form-control"
                    placeholder="e.g. https://www.youtube.com/watch"
                  />
                
                </div>
              </div>
              <div class="col-md-12">
                <div :class="['form-group mb-3', { 'has-error': !item.text && showError }]">
                  <label class="form-label fw-semibold">Texto del Tópico</label>
                  <input
                    v-model="item.text"
                    type="text"
                    class="form-control"
                    placeholder="Write your meta description"
                  />
                 
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group mb-3">
                  <label class="form-label fw-semibold">Subir Ficheros</label>
                  <ImageUpload @files-selected="handleFilesSelected" />
                </div>
              </div>
            </div>
            <div v-if="error" class="alert alert-danger mt-3">{{ error }}</div>
            <div v-if="success" class="alert alert-success mt-3">{{ message }}</div>
            <div class="modal-footer" style="display: flex; justify-content: space-between;">
              <button type="button" class="btn-cancel default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 rounded-1" data-bs-dismiss="modal">Cancelar</button>
              <button type="submit" class="btn-accept default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 rounded-1"> {{ title }} Tópico</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImageUpload from "./ImageUpload.vue";
import * as StateStore from "@/utils/store";

export default {
  name: "CreateNewTopicModal",
  props: {
    axios: {
      type: [Object, Function],
      required: true,
    },
    id: Number,
    noCloseOnBackdrop: Boolean,
    edit: Boolean,
    title: {
      type: String,
      required: true,
    },
  },
  components: {
    ImageUpload,
  },
  data() {
    return {
      error: "",
      showError: false,
      success: false,
      message: '',
      item: {
        id: null,
        name: '',
        text: '',
        urlVideo: '',
        educationalSections: null,
        files: [],
      },
      educationalSections: [],
      
    };
  },
  watch: {
    id(newId) {
      if (newId) {
        this.getTopicData(newId);
      }
      else if (newId === undefined) {
        this.item = {
          id: null,
          name: '',
          text: '',
          urlVideo: '',
          educationalSections: null,
          files: null,
        };
        
      }
    }
  },
  methods: {
    handleFilesSelected(files) {
      this.item.files = files;
      console.log('Ficheros seleccionados:', files);
    },
    async getData() {
      try {
        await StateStore.actions.actionsGetAllEducSectionsList({ axios: this.axios });
        this.educationalSections = StateStore.getters.getEducationalSectionsList();
      } catch (error) {
        console.error('Error al obtener secciones educativas:', error);
        this.error = 'Error al cargar las secciones educativas.';
      }
    },
    async getTopicData(id) {
      if (!id) return;
      try {
        await StateStore.actions.actionsGetArticles({ axios: this.axios, id });        
        this.item = await StateStore.getters.getArticlesRetrieve(); // Ajusta según la estructura de tu respuesta.
        this.item.educationalSections = this.item.educationalSections ? this.item.educationalSections.id : null;
        console.log('Tópico obtenido:', this.item);
      } catch (error) {
        console.error('Error al obtener el tópico:', error);
        this.error = 'Error al cargar los datos del tópico.';
      }
    },
    
    async submitForm() {
      this.showError = true;
      if (!this.item.name ||  !this.item.educationalSections) {
        this.error = 'Todos los campos son necesarios';
        return;
      }
      this.error = '';
      this.showError = false;
      this.success = true;
      
      try {
          if (!this.id) {
            console.log("this.id: ", this.item);
          // Creación
          await StateStore.actions.actionsCreateArticles({
            axios: this.axios,
          name: this.item.name,
          text: this.item.text,
          educationalSections: this.item.educationalSections,
          files: [1], // Reemplaza con la lógica de manejo de archivos actual.
          });
          this.message ='Tópico Creado con éxito...';
        } else{
          // Edición
          await StateStore.actions.actionsEditArticles({
            axios: this.axios,
            id: this.id,
          name: this.item.name,
          text: this.item.text,
          educationalSections: this.item.educationalSections,
          files: [1], // Reemplaza con la lógica de manejo de archivos actual.
          });
          this.message =  'Tópico Modificado con éxito...'  ;
        }

        // Emitimos el evento de actualización
        this.$emit('topic-saved');
      } catch (error) {
        console.error('Error al procesar la solicitud:', error);
        this.error = 'Ocurrió un error al procesar la solicitud.';
      }
    },
    handleBackdropClick() {
      if (this.noCloseOnBackdrop) return;
      this.closeModal();
    },
    closeModal() {
      this.$refs.mymodal.classList.remove('show');
      document.body.classList.remove('modal-open');
      this.$emit('close');
    },
  },
  async created() {
    await this.getData();
    if (this.id) {
      this.getTopicData(this.id);
    }
  },
};
</script>

<style scoped>
.has-error input,
.has-error select {
  border-color: red;
}

.alert-success {
  background-color: green;
  border-radius: 10px;
  font-weight: bold;
  color: white;
  padding: 10px;
  display: inline-block;
}

.alert-danger {
  background-color: red;
  border-radius: 10px;
  font-weight: bold;
  color: white;
  padding: 10px;
  display: inline-block;
}
</style>
